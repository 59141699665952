import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router);

//ROUTES
const router  = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/index-survey',
            name: 'index-survey',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "index-survey" */ './views/IndexSurvey.vue')
        },
        {
            path: '/print-survey/:id',
            name: 'print-survey',
            component: () => import(/* webpackChunkName: "print-preview-survey" */ './views/PrintPreviewSurvey')
        },
        {
            path: '/print-assignation/:hash',
            name: 'print-assignation',
            component: () => import(/* webpackChunkName: "print-preview-assignation" */ './views/PrintPreviewAssignation')
        },
        {
            path: '/survey/:hash',
            name: 'survey',
            component: () => import(/* webpackChunkName: "survey" */ './views/FillAssignation.vue')
        },
        {
            path: '/inspection-report/:hash',
            name: 'inspection-report',
            component: () => import(/* webpackChunkName: "report" */ './views/InspectionReport')
        },
        {
            path: '/sav-inspection-report/:hash',
            name: 'sav-inspection-report',
            component: () => import(/* webpackChunkName: "report" */ './views/SAVInspectionReport')
        },
        {
            path: '/index-question',
            name: 'index-question',
            component: () => import(/* webpackChunkName: "index-question" */ './views/IndexQuestion.vue')
        },
        {
            path: '/index-categories',
            name: 'index-categories',
            component: () => import(/* webpackChunkName: "index-categories" */ './views/IndexCategories.vue')
        },
        {
            path: '/index-tags',
            name: 'index-tags',
            component: () => import(/* webpackChunkName: "index-tags" */ './views/IndexTags.vue')
        },
        {
            path: '/index-assignations',
            name: 'index-assignations',
            component: () => import(/* webpackChunkName: "index-assignations" */ './views/IndexAssignations.vue')
        },
        {
            path: '/index-survey-types',
            name: 'index-survey-types',
            component: () => import(/* webpackChunkName: "index-survey-types" */ './views/IndexSurveyTypes.vue')
        },
        {
            path: '/edit-assignation/:id',
            name: 'edit-assignation',
            component: () => import(/* webpackChunkName: "edit-assignation" */ './views/EditAssignation.vue')
        },
        {
            path: '/user-management',
            name: 'user-management',
            component: () => import(/* webpackChunkName: "user-management" */ './views/UserManagement')
        },
        {
            path: '/index-maintenance',
            name: 'index-maintenance',
            component: () => import(/* webpackChunkName: "index-maintenance" */ './views/DashboardMaintenance')
        },
        {
            path: '/maintenance-stages',
            name: 'maintenance-stages',
            component: () => import(/* webpackChunkName: "maintenance-stages" */ './views/MaintenanceStages')
        },
        {
            path: '/edit-nc-maintenance/:id',
            name: 'edit-nc-maintenance',
            component: () => import(/* webpackChunkName: "edit-nc-maintenance" */ './views/EditNcMaintenance')
        },
        {
            path: '/nc-report/:hash',
            name: 'nc-report',
            component: () => import(/* webpackChunkName: "print-report" */ './views/NcReport.vue')
        },
        {
            path: '/index-nc-reports',
            name: 'index-nc-reports',
            component: () => import(/* webpackChunkName: "print-report" */ './views/IndexNcReports.vue')
        },
        {
            path: '/dev',
            name: 'dev-page',
            component: () => import(/* webpackChunkName: "dev-page" */ './views/IndexDev.vue')
        },
    ]
});

//ACCESS TOKEN VERIFICATION AND SESSION ACCESS LEVEL
router.beforeEach((to, from , next) => {
    //if user doesnt have a token, we redirect to login and save the page the user was heading to
    //we dont use this validation for the home route (login), otherwise it will redirect to home all the time which will cause a call stack exceeded error (causing by the infinite loop it will produce)
    //we dont use this validation for the survey and report routes because we can access them without being logged in
    if(!localStorage.getItem('access_token') && to.name !== 'home'  && to.name !== 'survey'  && to.name !== 'inspection-report' && to.name !== 'sav-inspection-report') {
        localStorage.setItem('goTo', to.path);
        next({ name: 'home' });
    }
    else if(sessionStorage.getItem('accessLevel') === 'shop' && !(to.name === 'nc-report' ||  to.name === 'index-nc-reports')){
        //IF the current sessionStorage accessLevel === 'shop' AND the user wants to access a page other than 'nc-report' OR 'index-nc-reports'
        //Just redirect them to 'index-nc-reports' route
        next({ name: 'index-nc-reports' });
    }
    //else, we keep going
    else {
        next();
    }
});

//go to page user was heading to if goTo item exist in local storage
router.beforeResolve((to, from, next) => {
    let goTo = localStorage.getItem('goTo');

    //if there is a path to go to in the local storage
    //we dont use this validation for the home route (login), otherwise it will redirect to home all the time which will cause a call stack exceeded error (causing by the infinite loop it will produce)
    //we dont use this validation for the survey and report routes because we can access them without being logged in
    if(!!goTo && to.name !== 'home' && to.name !== 'survey' && to.name !== 'inspection-report' && to.name !== 'sav-inspection-report') {
        localStorage.removeItem('goTo');
        router.replace(goTo);
    }
    else {
        next();
    }
});

export default router;
