<template>
    <v-dialog width="70%" persistent v-model="dialog">
        <v-card>
            <v-card-title :class="!!mobileDisplay ? 'subtitle-1' : ''">Assigner le Questionnaire</v-card-title>

            <v-card-text>
                <!-- assignation title -->
                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="assignationTitle" label="Titre"/>
                    </v-col>
                </v-row>

                <!-- default language dropdown menu -->
                <v-row>
                    <v-col cols="12">
                        <v-select
                            v-model="defaultLang"
                            :items="langOptions"
                            item-text="text"
                            item-value="value"
                            label="Langue par défaut"
                        />
                    </v-col>
                </v-row>

                <!-- assignation type dropdown menu -->
                <v-row>
                    <v-col cols="12">
                        <v-select
                            v-if="!isAutoAssigned"
                            v-model="selectedAssignationType"
                            :items="assignationTypes"
                            item-text="text"
                            item-value="value"
                            label="Type assignation"
                            @change="resetAssignationTypeInfo"
                        />
                    </v-col>
                </v-row>

                <!-- assigned user autocomplete -->
                <v-row v-if="selectedAssignationType === 'user'">
                    <v-col cols="6">
                        <v-autocomplete
                            v-model="selectedAssignedUser"
                            :search-input.sync="assignedUserSearch"
                            :items="users"
                            item-text="fullName"
                            return-object
                            label="Utilisateur à assigner"
                        />
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="newAssignationEmail" label="Email" readonly/>
                    </v-col>
                </v-row>

                <!-- assigned contact autocompletes (suppliers, contacts, emails) -->
                <v-row v-if="selectedAssignationType === 'contact'">
                    <v-col cols="4">
                        <v-autocomplete
                            v-model="selectedCandidateSupplier"
                            :search-input.sync="candidateSuppliersSearch"
                            :items="suppliers"
                            item-text="name"
                            return-object
                            label="Compagnie"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete
                            v-model="selectedSupplierContact"
                            :search-input.sync="supplierContactsSearch"
                            :items="supplierContacts"
                            item-text="fullName"
                            return-object
                            label="Contact"
                        />
                    </v-col>
                    <v-col cols="4">
                        <v-autocomplete
                            v-model="newAssignationEmail"
                            :search-input.sync="supplierContactEmailsSearch"
                            :items="supplierContactEmails"
                            label="Email"
                        />
                    </v-col>
                </v-row>

                <!-- email  -->
                <!-- appears when assignation type is email -->
                <v-row v-if="selectedAssignationType === 'email'">
                    <v-col cols="12">
                        <v-text-field v-model="newAssignationEmail" label="Email"/>
                    </v-col>
                </v-row>

                <!-- stage models autocomplete -->
                <v-row v-if="!!activeSurvey && !!activeSurvey.surveyType && !!activeSurvey.surveyType.stageModelNeeded">
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="stageModelId"
                            :items="stageModels"
                            item-text="model"
                            item-value="id"
                            label="Model de scène"
                        />
                    </v-col>
                </v-row>

                <!-- stage units autocomplete -->
                <v-row v-if="!!activeSurvey && !!activeSurvey.surveyType && !!activeSurvey.surveyType.stageUnitNeeded">
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="stageUnitId"
                            :items="stageUnits"
                            item-text="unit"
                            item-value="id"
                            label="Unité de scène"
                        />
                    </v-col>
                </v-row>

                <!-- client supplier  -->
                <v-row v-if="!!activeSurvey && !!activeSurvey.surveyType && !!activeSurvey.surveyType.clientNeeded">
                    <v-col cols="12">
                        <v-autocomplete
                            v-model="selectedClientSupplierId"
                            :search-input.sync="clientSuppliersSearch"
                            :items="clientSuppliers"
                            item-text="name"
                            item-value="id"
                            label="Nom de compagnie du client"
                        />
                    </v-col>
                </v-row>

                <!-- project number -->
                <v-row v-if="!!activeSurvey && !!activeSurvey.surveyType && !!activeSurvey.surveyType.projectNeeded">
                    <v-col cols="12">
                        <v-text-field v-model="projectNumber" label="Numéro de projet"/>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn @click="closeDialogNewAssignation">Cancel</v-btn>
                <v-btn color="primary" @click="saveNewAssignation">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        name: "NewAssignationDialog",
        props: ['activeSurvey', 'dialogNewAssignation', 'isAutoAssigned'],
        async created() {
            await Promise.all([
                this.fetchUsers(),
                this.fetchSuppliers(),
                this.fetchAllStageModels(),
                this.fetchAllStageUnits(),
            ]);
        },
        data() {
            return {
                //assignation title
                assignationTitle: '',
                //assignation email
                newAssignationEmail: '',
                //v-model of stage models autocomplete
                stageModelId: null,
                //v-model of stage units autocomplete
                stageUnitId: null,
                //project number
                projectNumber: null,

                //v-model language v-select
                defaultLang: 'fr',
                //default language drop down options
                langOptions: [
                    { text: 'Français', value: 'fr' },
                    { text: 'Anglais', value: 'en' },
                    { text: 'Espagnol', value: 'es' },
                ],

                //selected assignation type (v-model for assignation ty pe dropdown)
                selectedAssignationType: '',
                //assignation types
                assignationTypes: [
                    { text: 'Choisir un utilisateur (employé) dans la système', value: 'user' },
                    { text: 'Choisir un contact venant d\'une compagnie dans Maximizer', value: 'contact' },
                    { text: 'Entrer manuellement une adresse courriel', value: 'email' },
                ],

                //assigned user
                selectedAssignedUser: null,
                assignedUserSearch: '',

                //client supplier
                clientSuppliersSearch: '',
                selectedClientSupplierId: null,

                //candidate suppliers for assigned contact
                candidateSuppliersSearch: '',
                selectedCandidateSupplier: null,
                //supplier contacts
                supplierContacts: [],
                supplierContactsSearch: '',
                selectedSupplierContact: null,
                //supplier contact emails
                supplierContactEmails: [],
                supplierContactEmailsSearch: '',
            }
        },
        watch: {
            //ASSIGNED USER WATCHER FOR AUTOCOMPLETE
            assignedUserSearch(newVal) {
                if(!newVal) {
                    //it will reset newAssignationEmail as well
                    this.selectedAssignedUser = null;
                }
            },
            selectedAssignedUser(newVal) {
                return !!newVal ? this.newAssignationEmail = newVal.email : this.newAssignationEmail = '';
            },

            //CLIENT INFO WATCHER (if option has been selected)
            async clientSuppliersSearch(newVal) {
                if(!newVal) {
                    this.selectedClientSupplierId = '';
                    return;
                }

                !!newVal && newVal !== this.selectedClientSupplier && await this.fetchSuppliers({ includeTrashed: true, search: newVal, flag: 'clientSupplier' });
            },

            //SUPPLIERS WATCHERS FOR AUTOCOMPLETE
            //handles suppliers
            async candidateSuppliersSearch(newVal) {
                if(!newVal) {
                    this.newAssignationEmail = '';
                    this.selectedCandidateSupplier = null;
                    return;
                }

                !!newVal && newVal !== this.selectedCandidateSupplier && await this.fetchSuppliers({ includeTrashed: true, search: newVal, flag: 'assignedSupplierContact' });
            },
            selectedCandidateSupplier(newVal) {
                this.supplierContacts = !!newVal ?  newVal.allContacts : [];
            },
            //handles supplier contacts
            supplierContactsSearch(newVal) {
                if(!newVal) {
                    this.newAssignationEmail = '';
                    this.selectedSupplierContact = null;
                }
            },
            selectedSupplierContact(newVal) {
                this.supplierContactEmails = !!newVal ? [newVal.email1, newVal.email2, newVal.email3] : [];
            },
            //handles supplier contact emails
            supplierContactEmailsSearch(newVal) {
                if(!newVal) {
                    this.newAssignationEmail = '';
                }
            },
        },
        computed: {
            //VUEX
            ...mapState([
                'stageModels',
                'stageUnits',
                'suppliers',
                'clientSuppliers',
                'users',
            ]),

            //viewport breakpoints
            mobileDisplay() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return true;
                    case 'sm': return true;
                    case 'md': return false;
                    case 'lg': return false;
                    case 'xl': return false;
                }
            },

            //v-model of the dialog (returns value of dialogNewAssignation prop)
            //if we need to modify a prop that is a v-model, we have to do a computed property with a getter and a setter returning the prop value
            dialog: {
                //returns value of dialogNewAssignation prop
                get() {
                    //if we are opening the dialog, we set a default title
                    if(!!this.dialogNewAssignation) {
                        this.assignationTitle = this.activeSurvey.surveyType.name;
                    }

                    return this.dialogNewAssignation;
                },

                //sets dialog to the vew value of dialogNewAssignation prop every times it changes
                set(newVal) {
                    return newVal;
                },
            },
        },
        methods: {
            //VUEX
            ...mapActions([
                'fetchUsers',
                'fetchSuppliers',
                'fetchAllStageModels',
                'fetchAllStageUnits',
                'addSurveyAssignation',
            ]),


            //NEW ASSIGNATION
            closeDialogNewAssignation() {
                //resets type infos and dropdown
                this.selectedAssignationType = [];
                this.resetAssignationTypeInfo();

                //resets options info
                this.assignationTitle = '';
                this.selectedClientSupplierId = null;
                this.projectNumber = null;
                this.stageUnitId = null;

                //emitting to the parent component to close dialog (sets dialogNewAssignation and isAutoAssigned props to false if needed)
                //isAutoAssigned is always true when we use the component in IndexAssignation.vue (when closing it will only set dialogNewAssignation to false)
                this.$emit('closeDialogNewAssignation');
            },
            async saveNewAssignation() {
                const args = {
                    survey: this.activeSurvey,
                    title: this.assignationTitle,
                    defaultLang: this.defaultLang,
                    type: this.isAutoAssigned ? 'autoAssigned' : this.selectedAssignationType,
                    assignedUserId: this.selectedAssignedUser ? this.selectedAssignedUser.id : null,
                    assignedContactId: this.selectedSupplierContact ? this.selectedSupplierContact.id : null,
                    email: this.newAssignationEmail,

                    //options
                    stageModelId: this.stageModelId,
                    stageUnitId: this.stageUnitId,
                    clientId: this.selectedClientSupplierId,
                    projectNumber: this.projectNumber,
                    isAutoAssigned: this.isAutoAssigned,
                };


                const hash = await this.addSurveyAssignation(args);
                //if auto assigned we redirect the user to the survey in a new window and send still send a email with the link
                if(!!this.isAutoAssigned) {
                    window.open(`/survey/${hash}`, '_blank');
                }
                this.closeDialogNewAssignation();
            },

            //resets all assignation type infos fields when assignation type changed
            resetAssignationTypeInfo() {
                this.selectedAssignedUser = null;

                this.selectedCandidateSupplier = null;
                this.selectedSupplierContact = null;

                this.newAssignationEmail = '';
            },
        },
    }
</script>
