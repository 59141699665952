<template>
    <!-- first questionIndex is 0 (number is even)-->
    <!-- if modulus 2 of questionIndex is not 0 (number is odd) we display question with a grey background -->
    <!-- if modulus 2 of questionIndex is 0 (number is even) we display question with no background color (white as default) -->
    <v-container :id="'questionId_'+id" :ref="id" :tabindex="id" :class="questionIndex % 2 !== 0 ? 'py-0 my-2 grey lighten-3' : 'py-0 my-2'">
        <v-row v-if="!!question" class="py-0 my-0">
            <!-- checkbox activator, question title, optional message if question is optional -->
            <v-col :cols="titleWidth" class="print-space py-0 my-0">
                <v-checkbox
                    v-if="!!question.optionCheckboxActivator"
                    @change="emitAnswerChange"
                    v-model="answer.optionsValue.checkboxActivator"
                    :label="`${order} - ${setLanguage(question.title, selectedLang)}`"
                    :readonly="readonly"
                    class="my-0 py-0"
                />

                <h3 v-else :class="titleStyle">
                    {{ order }} - {{ setLanguage(question.title, selectedLang) }}

                    <span v-if="!!question.optionOptional" class="subtitle-1 primary--text">
                        {{ selectedLang === 'fr' ? ' (Cette question est facultative)' : selectedLang === 'en' ? '(This question is optional)' : '(Cette question est facultative)' }}
                    </span>

                    <span v-if="answer.isComplete && parentQuestionHeader">
                        <v-icon style="color: white;" class="title-check ml-1" small>check</v-icon>
                    </span>
                </h3>

                <!-- NC infos expansion panel if an NC has been created -->
                <v-expansion-panels
                    class="mb-6 border"
                    v-if="!!showNonConformite && (!!nonConformite || (!!answer && !!answer.nonConformites && answer.nonConformites[0]))"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <!-- NC number -->
                            <p>
                                <strong>{{ selectedLang === 'fr' ? 'Numéro NC: ' : selectedLang === 'en' ? 'NC number: ' : 'Numéro NC: ' }}</strong>
                                {{ !!nonConformite && !!nonConformite.numeroNc ? nonConformite.numeroNc : '' }}
                            </p>
                            <!-- type -->
                            <p>
                                <strong>Type: </strong>
                                {{ !!nonConformite && !!nonConformite.type ? nonConformite.type : '' }}
                            </p>
                            <!-- state -->
                            <p>
                                <strong>{{ selectedLang === 'fr' ? 'État: ' : selectedLang === 'en' ? 'State: ' : 'État: ' }}</strong>
                                {{ !!nonConformite && !!nonConformite.etatMachine ? nonConformite.etatMachine[selectedLang === 'fr' ? 'label_fr' : selectedLang === 'en' ? 'name' : 'label_fr'] : '' }}
                            </p>
                            <!-- createdAt -->
                            <p>
                                <strong>{{ selectedLang === 'fr' ? 'Date création: ' : selectedLang === 'en' ? 'Creation date: ' : 'Date création: ' }}</strong>
                                {{ !!nonConformite && !!nonConformite.createdAt ? nonConformite.createdAt :  '' }}
                            </p>
                            <!-- updatedAt -->
                            <p>
                                <strong>{{ selectedLang === 'fr' ? 'Dernière mise à jour: ' : selectedLang === 'en' ? 'Last update: ' : 'Dernière mise à jour: ' }}</strong>
                                {{ !!nonConformite && !!nonConformite.updatedAt ? nonConformite.updatedAt : '' }}
                            </p>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <h2>Informations</h2>
                            <br/>
                            <!-- id -->
                            <p>
                                <strong>Id: </strong>
                                {{ !!nonConformite && !!nonConformite.id ? nonConformite.id : '' }}
                            </p>
                            <!-- title -->
                            <p>
                                <strong>{{ selectedLang === 'fr' ? 'Titre: ' : selectedLang === 'en' ? 'Title: ' : 'Titre: ' }}</strong>
                                {{ !!nonConformite && !!nonConformite.titre ? nonConformite.titre : '' }}
                            </p>
                            <!-- description -->
                            <p>
                                <strong>Description: </strong>
                                {{ !!nonConformite && !!nonConformite.description ? nonConformite.description : '' }}
                            </p>
                            <!-- project number -->
                            <p>
                                <strong>{{ selectedLang === 'fr' ? 'Numéro projet: ' : selectedLang === 'en' ? 'Project number: ' : 'Numéro projet: ' }}</strong>
                                {{ !!nonConformite && !!nonConformite.projet ? nonConformite.projet : '' }}
                            </p>
                            <!-- stage unit -->
                            <p>
                                <strong>{{ selectedLang === 'fr' ? 'Unité de scène: ' : selectedLang === 'en' ? 'Stage unit: ' : 'Unité de scène: ' }}</strong>
                                {{ !!nonConformite && !!nonConformite.stageUnit ? nonConformite.stageUnit : '' }}
                            </p>
                            <!-- stage model -->
                            <p>
                                <strong>{{ selectedLang === 'fr' ? 'Modèle de scène: ' : selectedLang === 'en' ? 'Stage model: ' : 'Modèle de scène: ' }}</strong>
                                {{ !!nonConformite && !!nonConformite.stageModel ? nonConformite.stageModel : '' }}
                            </p>
                            <!-- answer id -->
                            <p>
                                <strong>{{ selectedLang === 'fr' ? 'Id réponse: ' : selectedLang === 'en' ? 'Answer id: ' : 'Id réponse: ' }}</strong>
                                {{ !!nonConformite && !!nonConformite.questionAnswerId ? nonConformite.questionAnswerId : '' }}
                            </p>
                            <!-- NC url to GNC -->
                            <p>
                                <strong>{{ selectedLang === 'fr' ? 'Lien GNC: ' : selectedLang === 'en' ? 'GNC url: ' : 'Lien GNC: ' }}</strong>
                                <a :href="!!nonConformite && !!nonConformite.ncURL ? nonConformite.ncURL : ''">
                                    {{ !!nonConformite && !!nonConformite.ncURL ? nonConformite.ncURL : '' }}
                                </a>
                            </p>

                            <!-- tags -->
                            <br/>
                            <div v-if="!!nonConformite && !!nonConformite.tags && nonConformite.tags.length > 0">
                                <h2>Tags</h2>
                                <p><v-chip v-for="(tag, index) in nonConformite.tags" :key="index" dark class="mr-1">{{ tag.title }}</v-chip></p>
                            </div>
                            <h2 v-else>{{ selectedLang === 'fr' ? 'Aucun tags' : selectedLang === 'en' ? 'No tags' : 'Aucun tags' }}</h2>

                            <!-- members -->
                            <br/>
                            <div v-if="!!nonConformite && nonConformite.abonnes.length > 0">
                                <h2>{{ selectedLang === 'fr' ? 'Liste des abonnés' : selectedLang === 'en' ? 'Members list' : 'Liste des abonnés' }}</h2>
                                <p v-for="member in nonConformite.abonnes">{{ member.fullName }}</p>
                            </div>
                            <h2 v-else>{{ selectedLang === 'fr' ? 'Aucun abonnés' : selectedLang === 'en' ? 'No members' : 'Aucun abonnés' }}</h2>

                            <!--<br/>
                            <div v-if="!!nonConformite && nonConformite.imagesFolder.length > 0">
                                <h2>Images</h2>
                                <p v-for="image in nonConformite.imagesFolder">{{ image }}</p>
                            </div>
                            <h2 v-else>Aucune images</h2>

                            <br/>
                            <div v-if="!!nonConformite && nonConformite.imagesSolutionFolder.length > 0">
                                <h2>Images solution</h2>
                                <p v-for="image in nonConformite.imagesSolutionFolder">{{ image }}</p>
                            </div>
                            <h2 v-else>Aucune images solution</h2>-->
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>

            <!-- question speed dial button -->
            <v-col :cols="speedDialWidth" class="text-right py-0 my-0">
                <!-- speed dial button -->
                <!--<v-speed-dial v-model="questionSpeedDialBtn" class="print-none" direction="left">
                    <template v-slot:activator>
                        <v-btn v-if="!!question && question.type !== 'compound'" v-model="questionSpeedDialBtn" color="transparent" fab small depressed>
                            <v-icon v-if="questionSpeedDialBtn">mdi-close</v-icon>
                            <v-icon v-else>more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-btn v-if="!!showNonConformite && question.type !== 'contact' && question.type !== 'address'" fab dark medium color="warning" title="Créer Non-Conformité" @click="openNCDialog">
                        NC
                    </v-btn>
                    <v-btn fab dark medium color="purple" @click="uploadImg">
                        <v-icon title="Ajouter imgae">image</v-icon>
                    </v-btn>
                </v-speed-dial>-->

                <!-- create NC button (appears only in assignation output) -->
                <v-btn
                    v-if="!!question && question.type !== 'compound' && question.type !== 'address' && question.type !== 'contact' && visibleDetails && !!assignationIdToOutput"
                    fab dark medium
                    class="print-none warning"
                    title="Créer Non-Conformité"
                    @click="openNCDialog"
                >
                    NC
                </v-btn>
            </v-col>

            <!-- DIALOGS -->
            <!-- explanation dialog -->
            <v-dialog v-model="explanationDialog" v-if="!!question.optionExplanation">
                <template v-slot:activator="{ on }">
                    <v-btn text fab x-small class="pb-1 print-none" v-on="on"><v-icon>contact_support</v-icon></v-btn>
                </template>

                <v-card>
                    <v-card-title>
                        {{ selectedLang === 'fr' ? 'Explications supplémentaires' : 'Additionnal Explication' }}
                    </v-card-title>

                    <v-card-text>
                        <div v-html="setLanguage(question.optionExplanation.value, selectedLang)"></div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer/>

                        <v-btn color="primary" @click="explanationDialog = false">
                            Fermer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- NC dialog -->
            <v-dialog v-model="ncDialog" width="50%">
                <v-card>
                    <v-card-title>Créer non conformité</v-card-title>

                    <v-card-text>
                        <!-- type dropdown menu -->
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    v-model="ncType"
                                    :items="ncTypes"
                                    label="Type"
                                    @change="getCorrectivesActionsByType"
                                />
                            </v-col>
                        </v-row>

                        <!-- INPUTS NC OF TYPE QUALITÉ AND MAINTEANCE -->
                        <v-row v-if="ncType === 'qualite' || ncType === 'maintenance'">
                            <v-col cols="12">
                                <!-- categorie down menu -->
                                <v-select
                                    v-model="ncCategorieId"
                                    :items="ncCategories"
                                    item-text="nom"
                                    item-value="id"
                                    label="Catégorie"
                                />

                                <!-- department dropdown menu -->
                                <v-select
                                    v-model="ncDepartmentId"
                                    :items="ncDepartments"
                                    item-text="nom"
                                    item-value="id"
                                    label="Département en cause"
                                    @change="getUnderDepartments"
                                />

                                <!-- under department dropdown menu -->
                                <v-select
                                    v-model="ncUnderDepartmentId"
                                    :items="filteredUnderDepartments"
                                    item-text="nom"
                                    item-value="id"
                                    label="Sous-département en cause"
                                />

                                <!-- cause dropdown menu -->
                                <v-select
                                    v-model="ncCauseId"
                                    :items="ncCauses"
                                    item-text="nom"
                                    item-value="id"
                                    label="Cause"
                                />
                            </v-col>
                        </v-row>

                        <!-- INPUTS NC OF TYPE SAV -->
                        <v-row v-if="ncType === 'sav'">
                            <v-col cols="12">
                                <!-- SAV state down menu -->
                                <v-select
                                    v-model="ncState"
                                    label="État"
                                    :items="ncSavStates"
                                    :item-text="selectedLang === 'fr' ? 'label_fr' : selectedLang === 'en' ? 'label_en' : 'label_fr'"
                                    item-value="name"
                                />

                                <!-- criticality dropdown menu -->
                                <v-select
                                    v-model="ncCriticality"
                                    label="Code criticité"
                                    :items="ncSavCriticality"
                                    :item-text="selectedLang === 'fr' ? 'text_fr' : selectedLang === 'en' ? 'text_en' : 'text_fr'"
                                >
                                    <template v-slot:item="{ item }">
                                        <p><strong>{{ item.value }}</strong> - {{ item[selectedLang === 'fr' ? 'text_fr' : selectedLang === 'en' ? 'text_en' : 'text_fr'] }}</p>
                                    </template>
                                </v-select>

                                <!-- recommended actions dropdown menu -->
                                <v-autocomplete
                                    v-model="ncRecommendedAction"
                                    label="Action recommendée"
                                    :items="ncCorrectivesActions"
                                    item-text="name"
                                    item-value="name"
                                >
                                    <!-- we put the language before the item but when searching it will only search for value property (name) -->
                                    <template v-slot:item="{ item }">
                                        <p><strong>{{ item.language }}</strong> - {{ item.name }}</p>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn color="primary" @click="createNC">
                            Créer
                        </v-btn>
                        <v-btn @click="closeNCDialog">
                            Fermer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <!-- QUESTION OPTION YESNO DETAILS -->
        <v-row v-if="!!question && !!question.optionYesNo && !!answer"  class="py-0 my-0">
            <v-col cols="12"  class="py-0 my-0">
                <!-- pre question description -->
                <div class="" v-html="setLanguage(question.optionYesNo.text, selectedLang)"/>
                <!-- rbValidations rules used here -->
                <v-radio-group
                    v-model="answer.optionsValue.optionYesNo"
                    :class="displayClass"

                    :readonly="readonly"
                    :error="!!isSubmit && !sectionIsOptional && !answer.isComplete  && !question.optionOptional"
                    :rules="rbValidations"
                    :success="sectionIsOptional"
                >
                    <v-radio
                        v-for="(value, index) in question.optionYesNo.value"
                        :label="setLanguage(value.label, selectedLang)"
                        :value="index"
                        :key="index"
                        @click="toggleRadio()"
                    />
                </v-radio-group>

                <!-- appears for printing only -->
                <p class="text-center print-answer" v-if="!!assignationIdToOutput">
                    <strong>{{ selectedLang === 'fr' ? 'Réponse:' : selectedLang === 'en' ? 'Answer:' : 'Réponse:' }}</strong> {{ getSingleChoiceAnswer() }}
                </p>
            </v-col>
        </v-row>

        <!--Corps de la question-->
        <v-row
            :class="!!question.optionCheckboxActivator ? `ml-${checkboxActivatorIndentation} my-0 py-0` : 'my-0 py-0'"
            v-if="visibleDetails"
        >
            <!-- question description -->
            <v-col cols="12" class="ma-0 pa-0" v-if="setLanguage(question.description, selectedLang) !== ''">
                <div v-html="setLanguage(question.description, selectedLang)" class="section-description"/>
            </v-col>

            <!-- QUESTION TYPE SINGLE -->
            <v-col cols="12" v-if="!!question && question.type === 'single'" class="ma-0 pa-0">
                <!-- rbValidations rules used here -->
                <v-radio-group
                    v-model="answer.value"
                    :class="displayClass"
                    @change="emitAnswerChange"
                    :readonly="readonly"
                    :error="!!isSubmit && !sectionIsOptional && !answer.isComplete  && !question.optionOptional"
                    :rules="rbValidations"
                >
                    <v-radio v-for="(choice, index) in question.details.choices" :key="index" :value="index" :label="setLanguage(choice, selectedLang)" />
                </v-radio-group>

                <v-text-field
                    type="number"
                    v-model="answer.optionsValue.quantity"
                    :label="selectedLang === 'fr' ? 'Quantité:' : 'Quantité'"
                    v-if="!!question.optionQuantity"
                    :rules="quantityValidations"
                    @input="emitAnswerChange"
                ></v-text-field>

                <v-select
                    v-model="answer.optionsValue.impactCode"
                    :items="criticalityMaintenance"
                    :label="selectedLang === 'fr' ? 'Code d\'impact:' : 'Impact code:'"
                    item-value="value"
                    :item-text="selectedLang === 'fr' ? 'text_fr' : 'text_en'"
                    v-if="!!question.optionImpactCode"
                    @input="emitAnswerChange"
                ></v-select>

                <!-- appears for printing only -->
                <p class="text-center print-answer" v-if="!!assignationIdToOutput">
                    <strong>{{ selectedLang === 'fr' ? 'Réponse:' : selectedLang === 'en' ? 'Answer:' : 'Réponse:' }}</strong> {{ getSingleChoiceAnswer() }}
                </p>
            </v-col>

            <!-- QUESTION TYPE MULTIPLE -->
            <v-col cols="12" v-if="!!question && question.type === 'multiple'" class="ma-0 pa-0">
                <!-- cbValidations rules used here -->
                <v-checkbox
                    v-for="(choice, index) in question.details.choices"
                    :key="index"
                    v-model="multipleChoice"
                    :value="index"
                    :label="setLanguage(choice, selectedLang)"
                    :class="displayClass"
                    @change="multipleChoiceChange"
                    :readonly="readonly"
                    :error="!!isSubmit && !sectionIsOptional && !answer.isComplete && !question.optionOptional && !answer.value.length"
                    :rules="cbValidations"
                >
                    <template v-slot:append>
                        <v-text-field
                            type="number"
                            v-model="answer.optionsValue.quantity[index]"
                            :label="selectedLang === 'fr' ? 'Quantité:' : 'Quantité'"
                            v-if="!!question.optionQuantity && multipleChoice.includes(index)"
                            :error-messages="!!isSubmit && !answer.optionsValue.quantity[index] ? ['Une valeur est requise.'] : null"
                            :rules="quantityValidations"
                            @input="emitAnswerChange"
                        ></v-text-field>
                        <v-select
                            v-model="answer.optionsValue.impactCode[index]"
                            :items="criticalityMaintenance"
                            :label="selectedLang === 'fr' ? 'Code d\'impact:' : 'Impact code:'"
                            item-value="value"
                            :item-text="selectedLang === 'fr' ? 'text_fr' : 'text_en'"
                            v-if="!!question.optionImpactCode && multipleChoice.includes(index)"
                            :error-messages="!!isSubmit && !(answer.optionsValue.impactCode[index] >= 0) ? ['Une valeur est requise.'] : null"
                            @change="emitAnswerChange"
                        ></v-select>
                    </template>
                </v-checkbox>

                <!-- appears for printing only -->
                <p class="text-center print-answer" v-if="!!assignationIdToOutput">
                    <strong>{{ selectedLang === 'fr' ? 'Réponse:' : selectedLang === 'en' ? 'Answer:' : 'Réponse:' }}</strong> {{ getMultipleChoiceAnswer() }}
                </p>
            </v-col>

            <!-- QUESTION TYPE LONGTEXT -->
            <v-col cols="12" v-if="!!question && question.type === 'text'" class="ma-0 pa-0">
                <!-- textareaValidations rules used here -->
                <v-textarea
                    outlined v-model="answer.value"
                    :class="displayClass"
                    :label="setLanguage(question.details.label, selectedLang)"
                    @input="emitAnswerChange"
                    :readonly="readonly"
                    :error="!!isSubmit && !sectionIsOptional && !answer.isComplete  && !question.optionOptional"
                    :rules="textareaValidations"
                />

                <!-- appears for printing only -->
                <p class="text-center print-answer" v-if="!!assignationIdToOutput">
                    <strong>{{ selectedLang === 'fr' ? 'Réponse:' : selectedLang === 'en' ? 'Answer:' : 'Réponse:' }}</strong> {{ answer.value || 'N/A' }}
                </p>
            </v-col>

            <!-- QUESTION TYPE SHORTTEXT -->
            <v-col cols="12" v-if="!!question && question.type === 'shorttext'" class="ma-0 pa-0">
                <!-- textareaValidations rules used here -->
                <v-text-field
                    v-model="answer.value"
                    :class="displayClass"
                    :label="setLanguage(question.details.label, selectedLang)"
                    @input="emitAnswerChange"
                    :readonly="readonly"
                    :error="!!isSubmit && !sectionIsOptional && !answer.isComplete  && !question.optionOptional"
                    :rules="textareaValidations"
                />

                <!-- appears for printing only -->
                <p class="text-center print-answer" v-if="!!assignationIdToOutput">
                    <strong>{{ selectedLang === 'fr' ? 'Réponse:' : selectedLang === 'en' ? 'Answer:' : 'Réponse:' }}</strong> {{ answer.value || 'N/A' }}
                </p>
            </v-col>

            <!-- QUESTION TYPE DATE -->
            <v-col cols="12" v-if="!!question && question.type === 'date'" class="ma-0 pa-0">
                <v-dialog v-model="datePicker" persistent width="290px">
                    <template v-slot:activator="{ on }">
                        <!-- dateValidations rules used here -->
                        <v-text-field
                            outlined
                            dense v-model="answer.value"
                            label="Date" prepend-icon="event"
                            readonly
                            v-on="on"
                            :class="displayClass"
                            :disabled="readonly"
                            :error="!!isSubmit && !sectionIsOptional && !answer.isComplete  && !question.optionOptional"
                            :rules="dateValidations"
                        />
                    </template>
                    <v-date-picker v-model="datePickerDate" scrollable>
                        <v-spacer />

                        <v-btn text color="primary" @click="closeDatePicker">Cancel</v-btn>
                        <v-btn text color="primary" @click="setDateAnswer">OK</v-btn>
                    </v-date-picker>
                </v-dialog>

                <!-- appears for printing only -->
                <p class="text-center print-answer" v-if="!!assignationIdToOutput">
                    <strong>{{ selectedLang === 'fr' ? 'Réponse:' : selectedLang === 'en' ? 'Answer:' : 'Réponse:' }}</strong> {{ answer.value || 'N/A' }}
                </p>
            </v-col>

            <!-- QUESTION TYPE TIME -->
            <v-col cols="12" v-if="!!question && question.type === 'time'" class="ma-0 pa-0">
                <v-dialog v-model="timePicker" persistent width="290px">
                    <template v-slot:activator="{ on }">
                        <!-- timeValidations rules used here -->
                        <v-text-field
                            outlined
                            dense
                            class="print-none"
                            v-model="answer.value"
                            label="Heure"
                            prepend-icon="access_time"
                            readonly
                            v-on="on"
                            :disabled="readonly"
                            :error="!!isSubmit && !sectionIsOptional && !answer.isComplete  && !question.optionOptional"
                            :rules="timeValidations"
                        />
                    </template>
                    <v-time-picker v-if="timePicker" v-model="timePickerTime" full-width>
                        <v-spacer />

                        <v-btn text color="primary" @click="closeTimePicker">Cancel</v-btn>
                        <v-btn text color="primary" @click="setTimeAnswer">OK</v-btn>
                    </v-time-picker>
                </v-dialog>

                <!-- appears for printing only -->
                <p class="text-center print-answer" v-if="!!assignationIdToOutput">
                    <strong>{{ selectedLang === 'fr' ? 'Réponse:' : selectedLang === 'en' ? 'Answer:' : 'Réponse:' }}</strong> {{ answer.value || 'N/A' }}
                </p>
            </v-col>

            <!-- QUESTION TYPE NUMBER -->
            <v-col cols="12" v-if="!!question && question.type === 'number'" class="ma-0 pa-0">
                <!-- textareaValidations rules used here -->
                <v-text-field
                    class="inline-block"
                    outlined
                    dense
                    :label="setLanguage(question.details.label, selectedLang)"
                    type="number"
                    v-model="answer.value"
                    :class="displayClass"
                    @input="emitAnswerChange"
                    :readonly="readonly"
                    :error="!!isSubmit && !sectionIsOptional && !answer.isComplete  && !question.optionOptional"
                    :rules="textareaValidations"
                    style="width: 350px"
                />
                <p class="inline-block title ml-3">
                    {{question.details.number.unit}}
                </p>

                <!-- appears for printing only -->
                <p class="text-center print-answer" v-if="!!assignationIdToOutput">
                    <strong>{{ selectedLang === 'fr' ? 'Réponse:' : selectedLang === 'en' ? 'Answer:' : 'Réponse:' }}</strong> {{ answer.value || 'N/A' }}
                </p>
            </v-col>

            <!-- QUESTION TYPE SCALE -->
            <v-col cols="12" v-if="!!question && question.type === 'scale'" class="ma-0 pa-0">
                <v-slider
                    :max="question.details.scale.max"
                    :min="question.details.scale.min"
                    :step="question.details.scale.step"
                    v-model="answer.value"
                    thumb-label="always"
                    class="mt-4 print-none"
                    @change="emitAnswerChange"
                    :readonly="readonly"
                />

                <!-- appears for printing only -->
                <p class="text-center print-answer" v-if="!!assignationIdToOutput">
                    <strong>{{ selectedLang === 'fr' ? 'Réponse:' : selectedLang === 'en' ? 'Answer:' : 'Réponse:' }}</strong> {{ answer.value || 'N/A' }}
                </p>
            </v-col>

            <!-- QUESTION TYPE COMPOUND -->
            <v-col v-if="!!object" cols="12" v-for="(child, index) in object.children" :key="index" class="ma-0 pa-0">
                <QuestionAnswer
                    :ref="`${child.id}-child-component`"
                    :id="child.id"
                    v-if="!!child"
                    :object="child"
                    :order="`${order}.${index + 1}`"
                    :sub="true"
                    :selectedLang="selectedLang"
                    :isSubmit="isSubmit"
                    :checkboxActivatorIndentation="checkboxActivatorIndentation + 3"
                    :showNonConformite="showNonConformite"
                    @answerChange="emitChildAnswerChange"
                    @imagesChange="emitChildImagesChange"
                    :readonly="readonly"
                    :question-index="questionIndex"
                    :assignation-id-to-output="assignationIdToOutput"
                    :print="print"
                    :parent-trail="questionTrail"
                />
            </v-col>

            <!--  PRINT QUESTION TYPE CONTACT -->
            <!-- appears for printing only -->
            <v-row v-if="!!question && question.type === 'contact'" class="text-left print-answer">
                <v-col cols="12">
                    <p>
                        <strong>{{ selectedLang === 'fr' ? 'Nom complet:' : selectedLang === 'en' ? 'Full name:' : 'Nom complet:' }}</strong>
                        {{ answer.value.firstName }} {{ answer.value.middleName }} {{ answer.value.lastName }}
                    </p>

                    <p>
                        <strong>{{ selectedLang === 'fr' ? 'Courriel:' : selectedLang === 'en' ? 'Email:' : 'Courriel:' }}</strong> {{ answer.value.confirmedEmail || 'N/A' }}
                    </p>

                    <p>
                        <strong>{{ selectedLang === 'fr' ? 'Téléphone:' : selectedLang === 'en' ? 'Phone:' : 'Téléphone:' }}</strong> {{ answer.value.phone || 'N/A' }}
                    </p>

                    <p>
                        <strong>{{ selectedLang === 'fr' ? 'Téléphone alternatif:' : selectedLang === 'en' ? 'Alternate phone:' : 'Téléphone alternatif:' }}</strong> {{ answer.value.alternatePhone || 'N/A' }}
                    </p>
                </v-col>
            </v-row>

            <!-- QUESTION TYPE CONTACT -->
            <v-row class="py-0 my-0" v-if="!!question && question.type === 'contact'">
                <!-- first name -->
                <v-col cols="12" class="mb-0 mt-5 py-0">
                    <!-- textareaValidations rules used here -->
                    <v-text-field
                        v-model="answer.value.firstName"
                        :label="selectedLang === 'fr' ? 'Prénom' : selectedLang === 'en' ? 'First name' : 'Prénom'"
                        :class="displayClass"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !answer.value.firstName  && !question.optionOptional"
                        :rules="textareaValidations"
                        @input="emitAnswerChange"
                    />
                </v-col>

                <!-- middle name -->
                <v-col cols="12" class="mx-0 mb-0 mt-7 py-0">
                    <!-- textareaValidations rules used here -->
                    <v-text-field
                        v-model="answer.value.middleName"
                        :class="displayClass"
                        :label="selectedLang === 'fr' ? 'Deuxième nom' : selectedLang === 'en' ? 'Middle name' : 'Deuxième nom'"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !answer.value.middleName  && !question.optionOptional"
                        :rules="textareaValidations"
                        @input="emitAnswerChange"
                    />
                </v-col>

                <!-- last name -->
                <v-col cols="12" class="mx-0 mb-0 mt-7 py-0">
                    <!-- textareaValidations rules used here -->
                    <v-text-field
                        v-model="answer.value.lastName"
                        :class="displayClass"
                        :label="selectedLang === 'fr' ? 'Nom de famille' : selectedLang === 'en' ? 'Last name' : 'Nom de famille'"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !answer.value.lastName  && !question.optionOptional"
                        :rules="textareaValidations"
                        @input="emitAnswerChange"
                    />
                </v-col>

                <!-- email -->
                <v-col cols="12" class="mx-0 mb-0 mt-7 py-0">
                    <v-text-field
                        v-model="contactEmail"
                        :class="displayClass"
                        :label="selectedLang === 'fr' ? 'Courriel' : selectedLang === 'en' ? 'Email' : 'Courriel'"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !contactEmail  && !question.optionOptional"
                        :rules="[
                          v => !v && 'Ne peut pas être vide',
                          v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Doit être un courriel valide'
                        ]"
                    />
                </v-col>

                <!-- email confirmation -->
                <v-col cols="12" class="mx-0 mb-0 mt-7 py-0">
                    <v-text-field
                        v-model="answer.value.confirmedEmail"
                        :class="displayClass"
                        :label="selectedLang === 'fr' ? 'Confirmation courriel' : selectedLang === 'en' ? 'Email confirmation' : 'Confirmation courriel'"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !answer.value.confirmedEmail  && !question.optionOptional"
                        :rules="[
                            v => !!v && v !== contactEmail && 'Doit être identique au courriel saisi',
                            v => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Doit être un courriel valide'
                        ]"
                        @input="emitAnswerChange"
                    />
                </v-col>

                <!-- phone number -->
                <v-col cols="12" class="mx-0 mb-0 mt-7 py-0">
                    <!-- textareaValidations rules used here -->
                    <v-text-field
                        v-model="answer.value.phone"
                        :class="displayClass"
                        :label="selectedLang === 'fr' ? 'Téléphone' : selectedLang === 'en' ? 'Phone' : 'Téléphone'"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !answer.value.phone  && !question.optionOptional"
                        :rules="textareaValidations"
                        @input="emitAnswerChange"
                    />
                </v-col>

                <!-- alternate phone number -->
                <v-col cols="12" class="mx-0 mb-0 mt-7 py-0">
                    <!-- textareaValidations rules used here -->
                    <v-text-field
                        v-model="answer.value.alternatePhone"
                        :class="displayClass"
                        :label="selectedLang === 'fr' ? 'Téléphone alternatif' : selectedLang === 'en' ? 'Alternate phone' : 'Téléphone alternatif'"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !answer.value.alternatePhone  && !question.optionOptional"
                        :rules="textareaValidations"
                        @input="emitAnswerChange"
                    />
                </v-col>
            </v-row>

            <!--  PRINT QUESTION TYPE ADDRESS -->
            <!-- appears for printing only -->
            <v-row v-if="!!question && question.type === 'address'" class="text-left print-answer">
                <v-col cols="12">
                    <p>
                        <strong>{{ selectedLang === 'fr' ? 'Adresse:' : selectedLang === 'en' ? 'Address:' : 'Adresse:' }}</strong> {{ answer.value.address || 'N/A' }}
                    </p>

                    <p>
                        <strong>{{ selectedLang === 'fr' ? 'Ville:' : selectedLang === 'en' ? 'City:' : 'Ville:' }}</strong> {{ answer.value.city || 'N/A' }}
                    </p>

                    <p>
                        <strong>{{ selectedLang === 'fr' ? 'Code postale:' : selectedLang === 'en' ? 'Postal code:' : 'Code postale:' }}</strong> {{ answer.value.postalCode || 'N/A' }}
                    </p>

                    <p>
                        <strong>{{ selectedLang === 'fr' ? 'Pays:' : selectedLang === 'en' ? 'Country:' : 'Pays:' }}</strong> {{ answer.value.country || 'N/A' }}
                    </p>

                    <p>
                        <strong>{{ selectedLang === 'fr' ? 'État/Province:' : selectedLang === 'en' ? 'State/Province:' : 'État/Province:' }}</strong> {{ answer.value.stateOrProvince || 'N/A' }}
                    </p>
                </v-col>
            </v-row>

            <!-- QUESTION TYPE ADDRESS -->
            <v-row class="py-0 my-0" v-if="!!question && question.type === 'address'">
                <!-- street address -->
                <v-col cols="12" class="mb-0 mt-5 py-0">
                    <!-- textareaValidations rules used here -->
                    <v-textarea
                        v-model="answer.value.address"
                        :label="selectedLang === 'fr' ? 'Adresse' : selectedLang === 'en' ? 'Address' : 'Adresse'"
                        :class="displayClass"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !answer.value.address  && !question.optionOptional"
                        :rules="textareaValidations"
                        @input="emitAnswerChange"
                    />
                </v-col>

                <!-- city -->
                <v-col cols="12" class="mx-0 mb-0 mt-7 py-0">
                    <!-- textareaValidations rules used here -->
                    <v-text-field
                        v-model="answer.value.city"
                        :class="displayClass"
                        :label="selectedLang === 'fr' ? 'Ville' : selectedLang === 'en' ? 'City' : 'Ville'"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !answer.value.city  && !question.optionOptional"
                        :rules="textareaValidations"
                        @input="emitAnswerChange"
                    />
                </v-col>

                <!-- zip code or postal code -->
                <v-col cols="12" class="mx-0 mb-0 mt-7 py-0">
                    <!-- textareaValidations rules used here -->
                    <v-text-field
                        v-model="answer.value.postalCode"
                        :class="displayClass"
                        :label="selectedLang === 'fr' ? 'Postal code' : selectedLang === 'en' ? 'Country' : 'Code postal'"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !answer.value.postalCode  && !question.optionOptional"
                        :rules="textareaValidations"
                        @input="emitAnswerChange"
                    />
                </v-col>

                <!-- country -->
                <v-col cols="12" class="mx-0 mb-0 mt-7 py-0">
                    <v-autocomplete
                        v-model="answer.value.country"
                        :items="countries"
                        item-text="name"
                        item-value="abbreviation"
                        :class="displayClass"
                        :label="selectedLang === 'fr' ? 'Pays' : selectedLang === 'en' ? 'Country' : 'Pays'"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !answer.value.country  && !question.optionOptional"
                        @change="resetStateOrProvince"
                        @input="emitAnswerChange"
                    />
                </v-col>

                <!-- state or province -->
                <v-col cols="12" class="mx-0 mb-0 mt-7 py-0">
                    <!-- textareaValidations rules used here -->
                    <v-autocomplete
                        v-if="answer.value.country === 'CA' || answer.value.country === 'US'"
                        v-model="answer.value.stateOrProvince"
                        :items="answer.value.country === 'CA' ? caProvinces : answer.value.country === 'US' ? usStates : []"
                        item-text="name"
                        item-value="abbreviation"
                        :class="displayClass"
                        :label="selectedLang === 'fr' ? 'État / Province' : selectedLang === 'en' ? 'State / Province' : 'État / Province'"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !answer.value.stateOrProvince  && !question.optionOptional"
                        @input="emitAnswerChange"
                    />

                    <v-text-field
                        v-else
                        v-model="answer.value.stateOrProvince"
                        :class="displayClass"
                        :label="selectedLang === 'fr' ? 'État / Province' : selectedLang === 'en' ? 'State / Province' : 'État / Province'"
                        :readonly="readonly"
                        :error="!!isSubmit && !sectionIsOptional && !answer.value.stateOrProvince  && !question.optionOptional"
                        @input="emitAnswerChange"
                    />
                </v-col>
            </v-row>
        </v-row>

        <!-- QUESTION OPTION COMMENTS -->
        <!-- comment are always optional so we dont put any validation on them but we make them read only once the survey is submitted definitly -->
        <v-row class="py-0 my-0" v-if="!!question && !!question.optionComments && visibleDetails">
            <v-col cols="12" class="ma-0 pa-0">
                <v-textarea
                    outlined
                    v-model="answer.optionsValue.optionComments"
                    :class="displayClass"
                    :label="selectedLang === 'fr' ? 'Commentaires' : 'Comments'"
                    :readonly="readonly"
                    @input="emitAnswerChange"
                />

                <!-- appears for printing only -->
                <p class="text-center print-answer" v-if="!!assignationIdToOutput">
                    <strong>{{ selectedLang === 'fr' ? 'Commentaire:' : selectedLang === 'en' ? 'Comment:' : 'Commentaire:' }}</strong> {{ answer.optionsValue.optionComments || 'N/A' }}
                </p>
            </v-col>
        </v-row>

        <!-- Images bundle viewer -->
        <v-row class="py-0 my-0 mb-0 pb-0" v-if="forceShowUpload || (!!question && question.type !== 'compound' && visibleDetails)">
            <div v-for="(image, index) in this.filteredImages" :key="index" class="image-wrapper">
               <v-img
                   :src="image.dataUrl"
                   :alt="`Resized image ${index + 1}`"
                   max-width="50px"
                   class="image-preview mr-1"
                   @click="imageClick(image)"
               />
                <div v-if="!image.info" class="img-indicator" >
                    <v-icon style="color:white;" x-small>check</v-icon>
                </div>
            </div>

            <v-col class="py-0 my-0 pl-0" cols="12">
                <!-- custom image count error message display -->
                <!-- filteredImages is an array of images used for UI (frontend) purposes only. When sending data to server (backend) we send the images array -->
                <span  v-if="!forceShowUpload && !!question && !!question.optionImages && filteredImages.length < question.optionImages.imageCount && !print && !question.optionOptional" class="caption red--text ml-2">
                    {{ mandatoryImagesLabel(question) }}
                </span>
                <span  v-if="forceShowUpload && filteredImages.length < question.optionYesNoImages.imageCount && !print" class="caption red--text ml-2">
                    {{ mandatoryYesNoImagesLabel(question) }}
                </span>
            </v-col>
        </v-row>

        <!-- Upload image button -->
        <v-row style="margin-top:-1px;" v-if="!readonly && (forceShowUpload || (!!question && question.type !== 'compound' && !hideUpload))">
            <v-col class="py-0 my-0 pl-2 pb-2" cols="12">
                <v-btn
                    fab
                    dark
                    small
                    elevation="0"
                    class="my-1 purple"
                    @click="$refs.fileInput.click()"
                >
                    <v-icon title="Ajouter des images">image</v-icon>
                </v-btn>

                <input
                    ref="fileInput"
                    type="file"
                    accept="image/*"
                    multiple
                    @change="handleImageUpload($event)"
                    @onComplete="endImageResize"
                    style="display: none"
                    class="indigo white--text d-none"
                />

                <v-btn
                    v-if="!readonly && (forceShowUpload || (!!question && question.type !== 'compound' && !hideUpload))"
                    fab
                    dark
                    small
                    elevation="0"
                    class="my-1 ml-1 purple"
                    @click="openCameraDialog"
                >
                    <v-icon title="Ouvrir camera">camera_alt</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <!-- image dialog (display image) -->
        <v-dialog v-model="imageDialog" v-if="!!imageToDisplay">
            <v-img
                :src="imageToDisplay.dataUrl"
                contain
                style="background-color: white;"
            >
                <v-btn
                    fab
                    class="ma-3"
                    color="primary"
                    @click.stop="closeImageDialog"
                >
                    <v-icon title="Ajouter image">close</v-icon>
                </v-btn>
                <v-btn
                    fab
                    class="ma-3"
                    :loading="isImageRotating"
                    @click.stop="rotateImage(imageToDisplay, 'L')"
                >
                    <v-icon color="blue" title="Tourner l'image vers la gauche">rotate_left</v-icon>
                </v-btn>
                <v-btn
                    fab
                    class="ma-3"
                    :loading="isImageRotating"
                    @click.stop="rotateImage(imageToDisplay, 'R')"
                >
                    <v-icon color="blue" title="Tourner l'image vers la droite">rotate_right</v-icon>
                </v-btn>
                <v-btn
                    fab
                    class="ma-3"
                    @click.stop="deleteImage(imageToDisplay)"
                >
                    <v-icon color="red" title="Supprimer image">delete</v-icon>
                </v-btn>
            </v-img>
        </v-dialog>

        <!-- Camera dialog (display camera) -->
        <v-dialog content-class="dialog-wrapper ma-0" v-model="cameraDialog">
            <v-card class="camera">
                <v-card-text class="web-camera-container">
                    <!-- Camera Loader -->
                    <div v-show="isCameraOpen && isLoading" class="camera-loading" >
                        <h2 style="width: 100%; text-align: center; font-weight: bolder;">Ouverture de la caméra</h2>
                        <ul class="loader-circle" style="margin-left: -45px;">
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>

                    <!-- Camera btns -->
                    <v-row v-show="isCameraOpen && !isLoading" style="width: 100%;">
                        <v-col class="pt-0 pr-0 pl-0 mb-3 mt-2" style="width: 100%;">
                            <v-icon class="mt-4 mr-2" style="float:right; font-weight: bolder;" @click="closeCameraDialog" title="Fermer caméra">close</v-icon>
                            <v-icon v-show="showPhotoTaken" style="float:right; font-weight: bolder;" title="Reprendre" class="button mt-4 mr-2" role="button" @click="showPhotoTaken = false">undo</v-icon>
                            <v-icon class="mt-5 mr-4 ml-2" color="purple" title="Prendre image" @click="takePhoto">camera_alt</v-icon>
                            <v-icon class="mt-4 mr-4" style="font-size: 20px;" color="black" title="Changer caméra" @click="switchCameraSide" v-show="this.hasMultipleCameras">cameraswitch</v-icon>
                            <v-icon class="mt-4 mr-2" color="success" id="downloadPhoto" title="Sauvegarder" download="my-photo.jpg" @click="downloadImage" v-if="isPhotoTaken">save</v-icon>
                        </v-col>
                    </v-row>

                    <!-- Camera Viewer -->
                    <div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" :class="{ 'flash' : isShotPhoto }">
                        <div class="camera-shutter" :class="{'flash' : isShotPhoto}"></div>

                        <video v-show="!showPhotoTaken" id="videoView" ref="videoView" autoplay playsinline></video>

                        <canvas v-show="showPhotoTaken" id="photoTaken" ref="canvas"></canvas>
                    </div>

                    <!--  Caméra multi image viewer  -->
                    <div v-if="photosTaken.length > 0" class="taken-photo-wrapper">
                        <div v-for="(photo, index) in photosTaken" :key="index" class="preview-taken-photo">
                            <v-img alt="photo" :src="photo" @click="showPhoto(index)" />
                            <v-checkbox v-model="selectedPhotos" :value="index" style="width:22px; height: 30px; margin:0 auto;"/>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import { mapState, mapActions, mapMutations } from 'vuex'
    import shared from '../../shared';
    import countries from '../../assets/countries.json';
    import usStates from '../../assets/usStates.json';
    import caProvinces from '../../assets/caProvinces.json';

    export default {
        name: "QuestionAnswer",
        props: [
            'id',
            'object',
            'order',
            'selectedLang',
            'sub',
            'showNonConformite',
            'readonly',
            'isSubmit',
            'assignationIdToOutput',
            'questionIndex',
            'checkboxActivatorIndentation',
            'print',
            'sectionIsOptional',
            //this is used to generate the "full question style" that is used in the NC creation
            'parentTrail'
        ],
        mounted() {
            this.checkQuestionLevel();

            if(!!this.object && !!this.object.question) {
                this.question = JSON.parse(JSON.stringify(this.object.question));
            }
            //if we are previewing a question from IndexQuestion.vue, we affect this.object
            else if(!!this.object && !this.object.question) {
                this.question = JSON.parse(JSON.stringify(this.object));
            }

            this.answer = JSON.parse(JSON.stringify(this.getQuestionAnswer(this.object.id)));
            if (this.answer && Array.isArray(this.answer.value)) {
                this.multipleChoice =  this.answer.value.slice();
            }

            //if question type is contact or address and answer is null
            if((this.question.type === 'contact' || this.question.type === 'address') && !this.answer.value) {
                this.answer.value = {};
            }

            //affecting imagesFolder of answer to images an filteredImages
            this.images = JSON.parse(JSON.stringify(this.answer.imagesFolder));
            this.filteredImages = JSON.parse(JSON.stringify(this.answer.imagesFolder));

            //creating boolean to tell if images is empty or not
            this.hasImage = this.images.length > 0;
            //affecting non confirmte of answer if one has already been created
            this.nonConformite = !!this.answer && !!this.answer.nonConformites ? this.answer.nonConformites[0] : {};

            //shared functions
            this.setLanguage = shared.setLanguage;

            //countries list, US states list and CA provinces et territories list
            this.countries = countries;
            this.usStates = usStates;
            this.caProvinces = caProvinces;

            //question trail generation
            this.questionTrail = this.parentTrail + ` > ${this.setLanguage(this.question.title, this.selectedLang)}`;

            if(!!this.question.optionQuantity) {
                if (this.question.type === 'multiple' && !Array.isArray(this.answer.optionsValue.quantity)) {
                    this.answer.optionsValue.quantity = [];
                }
            }

            if(!!this.question.optionImpactCode) {
                if (this.question.type === 'multiple' && !Array.isArray(this.answer.optionsValue.impactCode)) {
                    this.answer.optionsValue.impactCode = [];
                }
            }

            if(this.answer.optionsValue && this.answer.optionsValue?.optionYesNo !== undefined){
                //Question remplis
                this.isQuestionAnswer = true;
                this.optionAnswerSelected = this.answer.optionsValue.optionYesNo;
            }
        },
        data() {
            return {
                continueLoading: false,
                question: null,
                answer: null,

                //type contact question email
                contactEmail: '',

                //validations rules here (checkbox validation is in the computed property)
                textareaValidations: [
                    v => (v || '').length > 0 || 'Ne peut pas être vide'
                ],
                rbValidations: [
                    v => this.sectionIsOptional || v != null || 'Vous devez faire un choix'
                ],
                dateValidations: [
                    v => this.sectionIsOptional || !!v || 'Vous devez choisir une date'
                ],
                timeValidations: [
                    v => this.sectionIsOptional || !!v || 'Vous devez choisir une date'
                ],
                quantityValidations: [
                    v => ((v <= this.question.optionQuantity.maxValue || this.question.optionQuantity.maxValue === 0) && v >= this.question.optionQuantity.minValue) || this.quantityValidationString()//(v >= this.question.optionQuantity.minValue && (v <= this.question.optionQuantity.maxValue || this.question.optionQuantity.maxValue === 0)) || "Vous devez entrer une quantité"
                ],

                multipleChoice: [],
                datePicker: false,
                datePickerDate: null,
                timePicker: false,
                timePickerTime: null,
                explanationDialog: false,
                hasImage: false,
                //images is the array we send to the server (backend)
                images: [],
                //we use filteredImages array for the UI (frontend) but we send the images array to backend
                //we manipulate this array the same way than images array. The only exception is we are gonna filter this one when we delete an already uploaded image
                filteredImages: [],
                imageDialog: false,
                imageToDisplay: null,

                //NC
                nonConformite: {},
                ncDialog: false,
                isCreated: false,
                ncDepartmentId: null,
                ncUnderDepartmentId: null,
                ncCategorieId: null,
                ncCauseId: null,
                //v-model for NC type v-select (NC Dialog)
                ncType: 'qualite',
                //v-model for NC states v-select (currently for NC of type SAV only)
                ncState: '',
                //v-model for NC criticality v-select (currently for NC of type SAV only)
                //when no criticality is selected, we put 0 in the database (already set this way)
                ncCriticality: 0,
                //v-model for NC recommended action v-select (currently for NC of type SAV only)
                ncRecommendedAction: '',
                filteredUnderDepartments: [],
                //this is the full question trail, generated using the parentTrail prop, used for the title of the NC
                questionTrail: '',

                //NC types (NC dialog)
                ncTypes: [
                    { text: 'Qualité', value: 'qualite' },
                    { text: 'Maintenance', value: 'maintenance' },
                    { text: 'SAV', value: 'sav' },
                ],

                //NC SAV criticality
                ncSavCriticality: [
                    { value: 1, text_en: 'As new', text_fr: 'Comme neuf', },
                    { value: 2, text_en: 'Esthetics', text_fr: 'Esthétique' },
                    { value: 3, text_en: 'Not functional', text_fr: 'Non fonctionnel' },
                    { value: 4, text_en: 'Not functional, prevents certification', text_fr: 'Non fonctionnel, empêche certification' },
                    { value: 5, text_en: 'Ground', text_fr: 'Réside au sol' },
                ],

                //v-model v-speed-dial button
                questionSpeedDialBtn: false,

                //shared functions from shared.js
                setLanguage: function() {},

                //countries list, US states list and CA provinces et territories list
                countries: [],
                usStates: [],
                caProvinces: [],

                //Image ctrl
                maxPixels: 12000000, // 12 megapixels
                imageTarget:{},
                indexProcess:0,
                endProcess:0,

                //Camera ctrl
                cameraDialog: false,
                isCameraOpen: false,
                isPhotoTaken: false,
                isShotPhoto: false,
                isLoading: false,
                link: '#',

                cameraType: 'environment',
                hasMultipleCameras: false,

                photosTaken:[],
                selectedPhotos: [],
                showPhotoTaken: false,

                //Handler for toggle question
                isQuestionAnswer : false,
                optionAnswerSelected : null,

                parentQuestionHeader:true,
                isImageRotating: false,
            }
        },
        computed: {
            ...mapState(['activeAssignation', 'ncDepartments', 'ncUnderDepartments', 'ncCategories', 'ncCauses', 'ncCorrectivesActions', 'ncSavStates', 'criticalityMaintenance']),

            visibleDetails() {
                return (!!this.question && this.question.optionYesNo == null && this.question.optionCheckboxActivator == null) ||
                    (!!this.answer && !!this.question.optionYesNo && this.answer.optionsValue.optionYesNo != null && this.question.optionYesNo.value[this.answer.optionsValue.optionYesNo].action === true) ||
                    (!!this.answer && !!this.question.optionCheckboxActivator != null && this.answer.optionsValue.checkboxActivator === true) ||
                    (!!this.question && this.print)
            },

            forceShowUpload() {
                return (
                    !!this.question
                    && !!this.answer
                    && this.question.optionYesNo != null
                    && this.question.optionYesNoImages != null
                    && (!!this.answer && !!this.question.optionYesNo && (this.answer.optionsValue.optionYesNo == null || (this.answer.optionsValue.optionYesNo != null && this.question.optionYesNo.value[this.answer.optionsValue.optionYesNo].action !== true)))
                )
            },

            hideUpload() {
                return (
                    this.print
                    || (!!this.question && this.question.type === 'noanswer' && this.question.optionImages == null)
                    || (!!this.question && !!this.answer && this.question.optionCheckboxActivator != null && !this.answer.optionsValue.checkboxActivator)
                    || (!!this.answer && !!this.question.optionYesNo && (this.answer.optionsValue.optionYesNo == null || (this.answer.optionsValue.optionYesNo != null && this.question.optionYesNo.value[this.answer.optionsValue.optionYesNo].action !== true)))
                );
            },

            titleStyle() {
                if (this.sub === true) {
                    return "subtitle-1 darken font-weight-medium"
                }
                return "title"
            },

            //checkbox validations
            cbValidations() {
                return [!!this.multipleChoice && this.multipleChoice.length !== 0 || 'Vous devez faire un choix'];
            },
            filterdImages() {
                return this.images.filter(i => !i.toDelete);
            },
            //question title responsive with
            titleWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return '10';
                    case 'sm': return '10';
                    case 'md': return '11';
                    case 'lg': return '11';
                    case 'xl': return '11';
                }
            },
            //question speed dial button responsive with
            speedDialWidth () {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs': return '2';
                    case 'sm': return '2';
                    case 'md': return '1';
                    case 'lg': return '1';
                    case 'xl': return '1';
                }
            },
            displayClass() {
                if (this.print === true && !this.assignationIdToOutput) {
                    return 'ma-0 pa-0'
                }
                return 'ma-0 pa-0 print-none'
            }
        },
        methods: {
            ...mapActions(['fetchDepartments', 'fetchParamsList', 'fetchCorrectivesActionsByType', 'storeNC','saveRotatedImage']),
            ...mapMutations(['setIsLoading', 'setLoadingMessage', 'setShowLoadingButton']),

            //sets state or province to empty string and the answer to new value when coutry changes
            resetStateOrProvince() {
                this.answer.value.stateOrProvince = '';
                this.emitAnswerChange();
            },

            getQuestionAnswer(questionId) {
                const defaultAnswer = {
                    questionId: this.object.id,
                    value: null,
                    optionsValue: {},
                    isComplete: false,
                    images: [],
                    imagesFolder: [],
                };

                let answer = !!this.activeAssignation ? this.activeAssignation.answers.find((answer) => {
                    return answer.questionId === questionId;
                }) : defaultAnswer;

                if (this.question.optionCheckboxActivator != null) {
                    answer.isComplete = true;
                }

                return answer;
            },

            multipleChoiceChange() {
                this.answer.value = this.multipleChoice;
                this.emitAnswerChange();
            },

            //VALIDATIONS
            validateAnswer() {
                if (this.sectionIsOptional) {
                    this.answer.isComplete = isComplete;
                }
                else if(this.question.type === 'address') {
                    //if all fields have been answered correctly, we set answer.isComplete to true
                    this.answer.isComplete = !!this.answer.value && !!this.answer.value.address && !!this.answer.value.city && !!this.answer.value.postalCode &&
                        !!this.answer.value.country && !!this.answer.value.stateOrProvince;
                }
                else if(this.question.type === 'contact') {
                    //if all fields have been answered correctly, we set answer.isComplete to true
                    this.answer.isComplete = !!this.answer.value && !!this.answer.value.firstName && !!this.answer.value.middleName && !!this.answer.value.lastName &&
                        !!this.answer.value.confirmedEmail && this.answer.value.confirmedEmail === this.contactEmail  &&
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.answer.value.confirmedEmail) &&
                        !!this.answer.value.phone && !!this.answer.value.alternatePhone;
                }
                else if (this.question.type === 'noanswer' && this.question.optionImages == null) {
                    this.answer.isComplete = true;
                }
                else if (this.question.type === 'compound') {
                    let isComplete = true;

                    this.object.children.forEach((child) => {
                        let answer = this.getQuestionAnswer(child.id);
                        isComplete = isComplete && answer.isComplete;
                    });

                    this.answer.isComplete = isComplete;
                }
                else {
                    this.answer.isComplete = this.question.type === 'noanswer' ||
                        typeof this.answer?.value === 'number' ||
                        !!this.answer?.value?.length;

                    if (this.question.type === 'multiple' && (this.question.optionQuantity?.id || this.question.optionImpactCode?.id)) {
                        if(this.answer.value !== null){
                            for (let i = 0; i < this.answer.value.length; i++) {
                                const choiceIndex = this.answer.value[i];

                                if (
                                    (this.question.optionQuantity?.id && !this.answer.optionsValue.quantity[choiceIndex]) ||
                                    (this.question.optionImpactCode?.id && !(this.answer.optionsValue.impactCode[choiceIndex] >= 0))
                                ) {
                                    this.answer.isComplete = false;
                                }
                            }
                        }
                    }
                    //checks if answer has mandatory image(s) and if the user has upload the proper amount if it is mandatory
                    if (!!this.question.optionImages && this.filteredImages.length < this.question.optionImages.imageCount) {
                        this.answer.isComplete = false;
                    }
                }
            },

            quantityValidationString() {
                if (this.question.optionQuantity) {
                    if (this.question.optionQuantity.maxValue === 0) {
                        return `Minimum ${this.question.optionQuantity.minValue}`;
                    }
                    else {
                        return `Minimum ${this.question.optionQuantity.minValue}, Maximum ${this.question.optionQuantity.maxValue}`;
                    }
                }
                return '';
            },

            //sets the answer to complete if all validations are ok
            setIsComplete() {
                if (this.sectionIsOptional) {
                    this.answer.isComplete = true;
                }
                //validates yes/no option
                else if(this.question.optionYesNo != null && this.answer.optionsValue.optionYesNo != null) {
                    //if the answer is NOT the action trigger
                    if(this.question.optionYesNo.value[this.answer.optionsValue.optionYesNo].action === false) {
                        //Quand la section est obligatoire et qu'AU MINIMUM 1 image est obligatoire
                        if(this.question.optionYesNoImages?.imageCount >= 1){
                            //SI le nombre d'image demandé est bon... OK
                            if(this.filteredImages.length >= this.question.optionYesNoImages.imageCount){
                                this.answer.isComplete = true;
                            }
                        }
                        else{
                            this.answer.isComplete = true;
                        }
                    }
                    //if the answer is the action trigger
                    else {
                        this.validateAnswer();
                    }
                }
                //validates checkbox activator option
                else if (this.question.optionCheckboxActivator != null) {
                    //if checkbox is not activated, the answer is complete
                    if(!this.answer.optionsValue.checkboxActivator) {
                        this.answer.isComplete = true;
                    }
                    //if checkbox is activated, we validate the answer
                    else {
                        this.validateAnswer();
                    }
                }
                //validates by question type
                else {
                    this.validateAnswer();
                }
            },

            //IMAGES (SOME OLD METHOD)
            //gets the id of the input in the image uploader component and programmatically trigger it
            uploadImg() {
                this.setIsLoading(true);
                this.setLoadingMessage('Loading image...')
                this.setShowLoadingButton(true);
                var _this = this;

                const onFocusCallback = function(event) {
                    //code before the pause
                    setTimeout(function(){
                        _this.setIsLoading(_this.continueLoading);
                        _this.continueLoading = false;
                    }, 100);
                };

                const onInputChangeCallback = function (event) {
                    _this.continueLoading = true;
                };

                document.getElementById(this.order).addEventListener('change', onInputChangeCallback);
                window.addEventListener('focus', onFocusCallback);

                document.getElementById(this.order).click();

                this.setIsComplete();
            },
            endImageResize(arg) {
                this.setIsLoading(false);
                this.continueLoading = false;
            },
            setImage(file) {
                this.hasImage = true;
                file.toUpload = true;
                file.toDelete = false;
                //images is the array we send to the server (backend)
                this.images.push(file);
                //filteredImages array is for UI purposes only
                this.filteredImages.push(file);
                this.emitImagesChange();
            },
            imageClick(image) {
                this.imageToDisplay = image;
                this.imageDialog = true;
            },
            closeImageDialog() {
                this.imageDialog = false;
                this.imageToDisplay = null;
            },
            deleteImage(img) {
                if(confirm("Êtes-vous certain de vouloir supprimer cette image ?")) {
                    //if image already uploaded on server, delete from images array
                    if(img.toUpload) {
                        //images is the array we send to the server (backend)
                        this.images = this.images.filter(i => i.dataUrl !== img.dataUrl);
                        //filteredImages array is for UI purposes only
                        this.filteredImages = this.filteredImages.filter(i => i.dataUrl !== img.dataUrl);
                    }
                    else {
                        img.toDelete = true;
                        //images is the array we send to the server (backend)
                        this.images.forEach((image)=>{
                            if(image.fileName === img.fileName){
                                image.toDelete = true;
                            }
                        });

                        //filteredImages array is use for UI purposes only so we need to filter it when we delete an image already uploaded
                        this.filteredImages = this.filteredImages.filter(i => i.dataUrl !== img.dataUrl);
                    }

                    this.imageDialog = false;
                    this.emitImagesChange();
                }
            },
            //Rotate image left OR rigth and save it
            async rotateImage(img, side) {
                try {
                    this.isImageRotating = true; // Start loading

                    let url = img.dataUrl.split('questions-answers');
                    url = url[1].split('/');
                    let params = {
                        'nonConformiteId': url[1],
                        'questionNumber': url[2],
                        'urlImage': img.dataUrl,
                        'side': side
                    }
                    let reponse = await this.saveRotatedImage(params);
                    img.dataUrl = reponse['NewPathName'];
                    img.fileName = reponse['NewImageName'];

                } catch (error) {
                    console.error("An error occurred while rotating image:", error);
                } finally {
                    this.isImageRotating = false;
                }
            },
            //mandatory image(s) to download label
            mandatoryImagesLabel(question) {
                if(this.selectedLang === 'fr') {
                    return `Il vous reste obligatoirement à télécharger ${question.optionImages.imageCount - this.filteredImages.length} photo(s)`;
                }
                else if(this.selectedLang === 'en') {
                    return `You still have to download ${question.optionImages.imageCount - this.filteredImages.length} photo(s)`;
                }
            },
            //mandatory image(s) to download label
            mandatoryYesNoImagesLabel(question) {
                if(this.selectedLang === 'fr') {
                    return `Il vous reste obligatoirement à télécharger ${question.optionYesNoImages.imageCount - this.filteredImages.length} photo(s)`;
                }
                else /*if(this.selectedLang === 'en')*/ {
                    return `You still have to download ${question.optionYesNoImages.imageCount - this.filteredImages.length} photo(s)`;
                }
            },

            //pickers
            setDateAnswer() {
                this.answer.value = this.datePickerDate;
                this.closeDatePicker();
                this.emitAnswerChange();
            },
            closeDatePicker() {
                this.datePicker = false;
                this.datePickerDate = null;
            },
            setTimeAnswer() {
                this.answer.value = this.timePickerTime;
                this.closeTimePicker();
                this.emitAnswerChange();
            },
            closeTimePicker() {
                this.timePicker = false;
                this.timePickerTime = null;
            },

            //events
            emitAnswerChange() {
                this.setIsComplete();
                this.$emit('answerChange', {answer: this.answer, question: this.object});
            },
            emitChildAnswerChange(event) {
                this.$emit('answerChange', {answer: event.answer, question: event.question});
                this.emitAnswerChange();
            },
            emitImagesChange() {
                //change answer.isComplete if the answer has mandatory image(s)
                this.emitAnswerChange();
                this.$emit('imagesChange', {images: this.images, question: this.object});
            },
            emitChildImagesChange(event) {
                this.$emit('imagesChange', event);
            },

            //gets answer of question type single
            getSingleChoiceAnswer() {
                if(!this.question.optionYesNo) {
                    return !!this.question.details.choices[this.answer.value] ? this.setLanguage(this.question.details.choices[this.answer.value], this.selectedLang) : 'N/A';
                }
                else {
                    return !!this.question.optionYesNo.value[this.answer.optionsValue.optionYesNo] ? this.setLanguage(this.question.optionYesNo.value[this.answer.optionsValue.optionYesNo].label, this.selectedLang) : 'N/A';
                }
            },
            //gets all answers of question type multiple
            getMultipleChoiceAnswer() {
                if(!!this.answer.value) {
                    let answer = '';
                    for (let choice of this.answer.value) {
                        answer += `${this.setLanguage(this.question.details.choices[choice], this.selectedLang)}, `;
                    }
                    return answer.slice(0, -2);
                }

                return 'N/A'
            },

            /* NON CONFORMITE METHODS */
            //resets inputs of NC dialog when submitted or when NC type is changed
            resetNCDialogInputs() {
                //resets NC inputs of type Qualité and Maintenance
                this.ncDepartmentId = null;
                this.ncUnderDepartmentId = null;
                this.ncCategorieId = null;
                this.ncCauseId = null

                //resets NC inputs of type SAV
                this.ncState = '';
                this.ncCriticality = 0;
                this.ncRecommendedAction = '';
            },

            //open Nc dialog
            async openNCDialog() {
                if(this.answer.nonConformites.length > 0 || !!this.isCreated) {
                    return alert('Une non conformité a déjà été créé pour cette question');
                }

                await Promise.all([
                    this.fetchDepartments(),
                    this.fetchParamsList(),
                    this.fetchCorrectivesActionsByType(this.ncType),
                ]);

                this.ncDialog = true;
            },

            //close NC dialog
            closeNCDialog() {
                //resets NC dialog inputs
                this.resetNCDialogInputs();

                this.ncDialog = false;
            },

            //gets correctives actions by the selected type of NC (qualite, maintenance or sav)
            async getCorrectivesActionsByType() {
                //fetches correctives actions for selected NC type
                await this.fetchCorrectivesActionsByType(this.ncType);

                //resets NC dialog inputs
                this.resetNCDialogInputs();
            },

            //gets under departments of a specific department
            getUnderDepartments() {
                this.filteredUnderDepartments = this.ncUnderDepartments.filter(d => d.parentId === this.ncDepartmentId);
            },

            //NC creation
            async createNC() {
                let nc = {
                    titre: `Q${this.order} - ${this.questionTrail}`,
                    description: this.getNcAnswer(),
                    projet: this.activeAssignation.projectNumber,
                    stageUnit: !!this.activeAssignation.stageUnit ? this.activeAssignation.stageUnit.unit : null,
                    stageModel: !!this.activeAssignation.stageModel ? this.activeAssignation.stageModel.model : !!this.activeAssignation.stageUnit ? this.activeAssignation.stageUnit.genericModel : null,
                    stageId: !!this.activeAssignation.stageUnit ? this.activeAssignation.stageUnit.stageId : null,
                    stageUnitId: !!this.activeAssignation.stageUnit ? this.activeAssignation.stageUnit.id : null,
                    questionAnswerId: this.answer.id,
                    tags: !!this.question.tags ? this.question.tags : [],
                    type: this.ncType
                };

                //if NC is type of SAV
                if(this.ncType === 'sav') {
                    //if no state has been selected, it will be set to new (sav_new)
                    nc.etat = !!this.ncState ? this.ncState : 'sav_new';
                    //if no criticality is selected, it will be set to 0 in the database (already done this way)
                    nc.gravite = this.ncCriticality;
                    //if a recommended action has been selected, it will be set to true, else, it will be false
                    nc.actionCorrectiveRequise = !!this.ncRecommendedAction;
                    //if no recommended action has been selected, it will be set to empty string ('') in the database (already done this way)
                    nc.actionCorrectiveProposee = this.ncRecommendedAction;
                }
                //else if NC is type Qualite or Maintenance
                else {
                    nc.ncParentDepartementId = this.ncDepartmentId;
                    nc.ncDepartementId = this.ncUnderDepartmentId;
                    nc.ncCategorieId = this.ncCategorieId;
                    nc.ncCauseId = this.ncCauseId;
                }

                //afftecting the newly created non confirmte to nonConformite
                this.nonConformite = await this.storeNC(nc);
                this.isCreated = true;
                this.closeNCDialog();

                //creates a time gap so we can set the screen properly for NC creation confirmation message
                let timer = new Promise(function(res, rej) {
                    window.setTimeout(function () {
                        res();
                    }, 100);
                });
                timer.then(res =>  alert(`La non conformité #${this.nonConformite.numeroNc} a été créé`));
            },

            //gets NC description without html p tags
            getNcDescription() {
                const description = this.setLanguage(this.question.description, this.selectedLang);
                return description.slice(3, -4);
            },

            //gets NC answer
            getNcAnswer() {
                //if question has option yes, no details
                if(!!this.question.optionYesNo) {
                    //if chosen answer is not the trigger action
                    for(let option of this.question.optionYesNo.value) {
                        for(let lang of option.label) {
                            //if the label language is the same than selected language of Gallup and the value is not the trigger action one
                            if(lang.code === this.selectedLang && lang.text === this.getSingleChoiceAnswer() && !option.action) {
                                return `${this.getSingleChoiceAnswer()}`;
                            }
                        }
                    }

                    //if question is type multiple
                    if(this.question.type === 'multiple') {
                        return `${this.getSingleChoiceAnswer()} ${this.getMultipleChoiceAnswer()}`;
                    }

                    //else, for any other types
                    return `${this.getSingleChoiceAnswer()} ${this.answer.value}`;
                }
                //if question is type single
                else if(this.question.type === 'single') {
                    return this.getSingleChoiceAnswer();
                }
                //if question is type multiple
                else if(this.question.type === 'multiple') {
                    return this.getMultipleChoiceAnswer();
                }

                return  this.answer.value;
            },

            //Image ctrl
            handleImageUpload(event) {
                const files = event.target.files;

                if (files.length > 0) {
                    this.indexProcess = 0;
                    this.endProcess = files.length;
                    Array.from(files).forEach(this.processFile);
                }

                this.$refs.fileInput.value = null;

                // Reload the page after a delay of 500 milliseconds *KB* (À voir...)
                // setTimeout(function() {
                //     window.location.reload();
                // }, 500);
            },
            processFile(file) {
                const reader = new FileReader();
                reader.onload = (e) => this.resizeImage(e.target.result, file);//this.images AND this.filteredImages are manipulate here
                reader.readAsDataURL(file);
            },
            resizeImage(src, file) {
                const img = new Image();
                img.onload = () => {
                    this.indexProcess++;
                    this.hasImage = true;
                    const dateNow = new Date();
                    const width = img.width;
                    const height = img.height;
                    const imagePixels = width * height;
                    let resizedUrl, newWidth, newHeight, fileType, fileName;

                    if(file) {
                        fileType = file.name;
                        fileName = file.name;
                    }
                    else {
                        fileType = 'image/jpeg';
                        fileName = 'camera-pic.jpeg';
                    }

                    if (imagePixels > this.maxPixels) {
                        const scaleFactor = Math.sqrt(this.maxPixels / imagePixels);
                        newWidth = Math.floor(width * scaleFactor);
                        newHeight = Math.floor(height * scaleFactor);

                        const canvas = document.createElement('canvas');
                        canvas.width = newWidth;
                        canvas.height = newHeight;

                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0, newWidth, newHeight);

                        resizedUrl = canvas.toDataURL('image/png');
                    } else {
                        resizedUrl = src;
                        newWidth = width;
                        newHeight = height;
                    }

                    this.imageTarget = {
                        dataUrl: resizedUrl,
                        info:{
                            aspectRatio:1,
                            modifiedDate: dateNow,
                            modifiedTimestamp: dateNow.getTime(),
                            name:fileName,
                            newHeight:newHeight,
                            newWidth:newWidth,
                            orgHeight:height,
                            orgWidth:width,
                            type:fileType
                        },
                        exif:null,
                        toUpload:true,
                        toDelete:false,
                    };

                    //images is the array we send to the server (backend)
                    this.images.push(this.imageTarget);

                    //filteredImages array is for UI purposes only
                    this.filteredImages.push(this.imageTarget);

                    if(this.indexProcess === this.endProcess){
                        this.emitImagesChange();
                    }
                };
                img.src = src;
            },

            //Camera ctrl
            openCameraDialog(){
                this.checkForMultipleCameras();
                this.cameraDialog = true;
                this.isCameraOpen = true;
                this.createCameraElement();
            },
            async checkForMultipleCameras() {
                try {
                    const devices = await navigator.mediaDevices.enumerateDevices();
                    const videoDevices = devices.filter(device => device.kind === 'videoinput');
                    this.hasMultipleCameras = videoDevices.length > 1;
                } catch (error) {
                    console.error("Error enumerating media devices", error);
                }
            },
            createCameraElement() {
                this.isLoading = true;

                const constraints = (window.constraints = {
                    audio: false,
                    video: {
                        facingMode: { ideal: this.cameraType },
                    },
                });

                navigator.mediaDevices
                    .getUserMedia(constraints)
                    .then(stream => {
                        this.currentStream = stream;
                        console.log('Camera in use:', stream.getVideoTracks()[0].label);

                        this.isLoading = false;
                        this.$refs.videoView.srcObject = stream;
                    })
                    .catch(error => {
                        this.isLoading = false;
                        alert("May the browser didn't support or there is some errors.");
                    });
            },
            stopCameraStream() {
                let tracks = this.$refs.videoView.srcObject.getTracks();

                tracks.forEach(track => {
                    track.stop();
                });
            },
            takePhoto() {
                if(!this.isPhotoTaken) {
                    this.isShotPhoto = true;

                    const FLASH_TIMEOUT = 50;

                    setTimeout(() => {
                        this.isShotPhoto = false;
                    }, FLASH_TIMEOUT);
                }

                this.isPhotoTaken = true;

                const context = this.$refs.canvas.getContext('2d');
                context.drawImage(this.$refs.videoView, 0, 0, this.$refs.videoView.clientWidth, this.$refs.videoView.clientHeight);

                // Save temporarily the new image in photosTaken array
                let imgDataUrl = this.$refs.canvas.toDataURL("image/png");
                this.photosTaken.push(imgDataUrl);
            },
            downloadImage() {
                this.indexProcess = 0;
                this.endProcess = this.selectedPhotos.length;

                if(this.selectedPhotos.length > 0){
                    this.selectedPhotos.forEach((index)=>{
                        let image = this.photosTaken[index];
                        this.resizeImage(image, null);
                    });

                    this.photosTaken = [];
                    this.selectedPhotos = [];

                    this.closeCameraDialog();
                }
                else{
                    alert('Vous devez selectionner au minimum 1 photo!');
                }
            },
            closeCameraDialog(){
                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.isShotPhoto = false;
                this.stopCameraStream();
                this.cameraDialog = false;
            },
            switchCameraSide() {
                if (
                    !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    )
                ) {
                    // false for not mobile device
                    alert('You dont have another camera to switch to');
                    return;
                }
                if (this.isCameraOpen) {
                    this.stopCameraStream();
                }
                this.cameraType = this.cameraType === 'user' ? 'environment' : 'user';
                this.createCameraElement();
            },

            showPhoto(index){
                this.showPhotoTaken = true;
                let image = new Image();
                image.src = this.photosTaken[index];
                image.onload = () => {
                    let context = this.$refs.canvas.getContext('2d');
                    context.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height); // Clears the canvas
                    context.drawImage(image, 0, 0, 450, 337.5); // Draws the selected image on the canvas
                }
            },

            //Toggle YesNo question
            toggleRadio () {
                if(this.isQuestionAnswer){
                    if(this.optionAnswerSelected === this.answer.optionsValue.optionYesNo){
                        //On toggle ici uniquement
                        this.answer.isComplete = false;
                        this.answer.optionsValue = {};
                        this.isQuestionAnswer = false;
                        this.optionAnswerSelected = null;
                    }
                }
                else{
                    this.isQuestionAnswer = true;
                }

                this.optionAnswerSelected = this.answer.optionsValue.optionYesNo;
                this.emitAnswerChange();
            },

            checkQuestionLevel(){
                let formatStr = this.order.toString();
                let dots = formatStr.match(/\./g);

                if(dots && dots.length > 1){
                    this.parentQuestionHeader = false;
                }
            }
        },
        watch: {
            parentTrail: function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    //question trail generation
                    this.questionTrail = newVal + ` > ${this.setLanguage(this.question.title, this.selectedLang)}`;
                }
            },
        }
    }
</script>

<style>
    .title-check{
        font-size: 12px;
        border-radius: 14px;
        border: 1px solid #4caf50;
        background-color: #4caf50;
        padding: 2px;
    }

    .inline-block {
        display: inline-block;
    }

    /* NC v-expansion panel border */
    .border {
        border: 2px solid #FFB300;
    }

    /* print stylesheet */
    .print-answer {
        display: none;
    }

    @media print {
        .print-none {
            display: none;
        }

        .print-answer {
            display: block;
            margin: 0;
            padding: 0;
            break-inside: avoid;
        }

        .print-space {
            margin: 0;
            padding: 0;
        }
    }

    .image-wrapper {
        position: relative;
        width: 50px;
        height: 50px;
        margin-right: 5px;
    }

    .image-preview{
        width: 100%;
        height: 100%;
    }

    .img-indicator {
        padding: 8px;
        top: -5px;
        right: -8px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: limegreen;
        display: flex;
        align-items: center; /* vertically aligns the icon */
        justify-content: center; /* horizontally aligns the icon */
        position: absolute;
    }

    /*Css Camera
    .dialog-wrapper.v-dialog {
        max-width: 500px;
        min-height: 250px;
    }*/

    .camera {
        background-color: #c5c5c5;
        margin-top: 25px;
        width: 500px;
        min-height: 250px;
        margin:0 auto;
    }

    .web-camera-container {
        min-height: 250px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 500px;
        background-color: #c5c5c5;
    }

    .web-camera-container .camera-download {
        height:80px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .web-camera-container .camera-box .camera-shutter {
        opacity: 0;
        width: 450px;
        height: 337.5px;
        background-color: #fff;
        position: absolute;
        margin:0 auto;
    }

    .web-camera-container .camera-box .camera-shutter.flash {
        opacity: 1;
    }

    .web-camera-container .taken-photo-wrapper {
        width:100%;
        height: 120px;
        display: inline-flex;
        overflow-x: auto;
        padding-bottom: 4px;
        padding-top: 4px;
    }

    .web-camera-container .taken-photo-wrapper .preview-taken-photo{
        width:24%;
        margin:0 auto;
    }

    .web-camera-container .camera-loading {
        overflow: hidden;
        padding-top: 50px;
        position: absolute;
        width: 500px;
        margin: 0;
    }

    .web-camera-container .camera-loading ul {
        position: absolute;
        width: 100%;
        z-index: 999999;
        margin: 0;
    }

    .web-camera-container .camera-loading .loader-circle {
        display: block;
        height: 14px;
        margin: 0 auto;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        transform: translateX(-50%);
        position: absolute;
        width: 100%;
        padding: 0;
    }

    .web-camera-container .camera-loading .loader-circle li {
        display: block;
        float: left;
        width: 24px;
        height: 24px;
        line-height: 24px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;
    }

    .web-camera-container .camera-loading .loader-circle li:nth-child(2) {
        animation-delay: 0.2s;
    }

    .web-camera-container .camera-loading .loader-circle li:nth-child(3) {
        animation-delay: 0.4s;
    }

    .v-dialog:not(.v-dialog--fullscreen) {
        max-height: 100%;
    }

    @media (max-width: 600px) {
        canvas#photoTaken {
            height: auto;
        }

        .dialog-wrapper.v-dialog {
            width: 100%;
        }

        .v-dialog > .v-card > .v-card__text {
            padding: 0;
        }

        .camera,
        .web-camera-container{
            height: auto;
            width: 100%;
            margin:0 auto;
        }

        .web-camera-container .camera-box .camera-shutter{
            width: auto;
        }

        #videoView{
            height: auto;
            width: 100%;
        }

        ::v-deep .section-description img{
            /*border:1px solid red;*/
            max-width: 350px;
        }

        img{width: 100%;}
    }

    @keyframes preload {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0.4;
        }
        100% {
            opacity: 1;
        }
    }
</style>
