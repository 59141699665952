import axios from 'axios';
import store from './store';
import router from './router';
import { ENV } from './constants'

let message = {
    type: '',
    text: ''
};

/*Timeout de 6sec par défaut pour toutes requêtes*/
const $axios = axios.create({
    baseURL: ENV.API_URL,
    timeout: 1000 * 60
});

/*Axios Interceptors */

// Add a request interceptor
$axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const token = store.getters['getAccessToken'];
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    //On enlève le timeout sur tous les GET requests, car celles-ci peuvent être longues
    if(config.method.toLowerCase() === 'get'){
        config.timeout = `0`;
    }
    return config;

}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
// $axios.interceptors.response.use(function (response) {
//     // Do something with response data
//     if(response.data && response.data.status){
//         if (response.data.status === ENV.ERROR_TOKEN_EXPIRED) {
//             message.type = "error";
//             message.text = "Votre session est expirée, veuillez vous reconnecter.";
//             store.dispatch(APPLICATION_STORE.actions.AFFICHER_MESSAGE,message);
//             store.dispatch(LOGIN_STORE.actions.LOGGED_OUT);
//             router.push({ name: 'home'});
//         }
//
//         if (response.data.status === ENV.ERROR_TOKEN_NOT_FOUND) {
//             store.dispatch(LOGIN_STORE.actions.LOGGED_OUT);
//             router.push({ name: 'home'});
//         }
//
//     }
//     return response;
// }, function (error) {
//     // Do something with response error
//     if(error.code){
//         if(error.code === ENV.ERROR_CODE_CONNECTION_TIMEOUT){
//             store.dispatch(APPLICATION_STORE.actions.AFFICHER_MESSAGE,{type: 'error', 'text' : 'Erreur connexion ! Veuillez réessayer plus tard.'});
//         }
//     }
//     return Promise.reject(error);
// });

export default $axios;
