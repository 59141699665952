<template>
    <v-card :class="currentClass">
        <v-card-title class="main-title">
            <span class="txt-blue">D</span><span class="txt-e">E</span><span class="txt-red">V</span>&nbsp;M<span class="txt-green">O</span><span class="txt-d">D</span><span class="txt-black">E</span>
            <v-spacer></v-spacer>
                <table style="border-spacing: 0; line-height: 1; padding:0;">
                <tr>
                    <td></td>
                    <td><v-icon class="clickable" @click="switchPosition('U')">arrow_drop_up</v-icon></td>
                    <td></td>
                </tr>
                <tr>
                    <td><v-icon class="clickable" @click="switchPosition('L')">arrow_left</v-icon></td>
                    <td></td>
                    <td><v-icon class="clickable" @click="switchPosition('R')">arrow_right</v-icon></td>
                </tr>
                <tr>
                    <td></td>
                    <td><v-icon class="clickable" @click="switchPosition('D')">arrow_drop_down</v-icon></td>
                    <td></td>
                </tr>
            </table>
        </v-card-title>
        <v-card-text>
            <v-list class="list-wrapper">
                <v-list-item class="list-item-wrapper" v-for="(lineMessage, index) in devMessages" :key="index">
                    <v-list-item-content class="list-item">
                        <v-list-item-title>- {{lineMessage}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "DevBuilder",
    props: [
        'devMessages',
    ],
    data() {
        return {
            currentClass: 'main-card top-left',
            currentPosition: ['U', 'L']
        }
    },
    methods:{
        switchPosition(position){
            let oldUpDownPosition = this.currentPosition[0];
            let oldLeftRightPosition = this.currentPosition[1];
            let newUpDownPosition = '';
            let newLeftRightPosition = '';

            switch (position){
                case 'L': newLeftRightPosition = 'L';
                    break;
                case 'R': newLeftRightPosition = 'R';
                    break;
                case 'U': newUpDownPosition = 'U';
                    break;
                case 'D':newUpDownPosition = 'D';
                    break;
            }

            if(newUpDownPosition === ''){
                newUpDownPosition = oldUpDownPosition;
            }

            if(newLeftRightPosition === ''){
                newLeftRightPosition = oldLeftRightPosition;
            }

            if(newUpDownPosition !== oldUpDownPosition || newLeftRightPosition !== oldLeftRightPosition){
                //Déplacement à faire
                this.currentPosition[0] = newUpDownPosition;
                this.currentPosition[1] = newLeftRightPosition;

                let currentPositionClass = 'main-card ';
                if(this.currentPosition[0] === 'U' && this.currentPosition[1] === 'R'){
                    //Top Right
                    currentPositionClass += 'top-right';
                }
                else if(this.currentPosition[0] === 'U' && this.currentPosition[1] === 'L'){
                    //Top Left
                    currentPositionClass += 'top-left';
                }
                else if(this.currentPosition[0] === 'D' && this.currentPosition[1] === 'L'){
                    //Bottom Left
                    currentPositionClass += 'bottom-left';
                }
                else{
                    //Bottom Right
                    currentPositionClass += 'bottom-right';
                }
                this.currentClass = currentPositionClass;
            }
        }
    },
}
</script>

<style scoped>
    .main-card{
        z-index:1000;
        position:absolute;
        width: 350px;
        min-height: 250px;
        background-color: #818181;
    }

    .top-left{
        top:80px;
        left:20px;
    }

    .top-right{
        top:80px;
        right:20px;
    }

    .bottom-left{
        bottom:100px;
        left:20px;
    }

    .bottom-right{
        bottom:100px;
        right:20px;
    }

    .main-title{
        color:#FFFFFF;
        margin:0;
        padding:0;
        padding-left:18px;
    }

    .list-wrapper{
        background-color: transparent;
        padding: 0;
    }

    .list-item-wrapper{
        height: 25px;
        padding:0;
        margin:0;
    }

    .list-item{
        color: white;
        padding:0;
        height: 25px;
    }

    .txt-blue{color:blue;}
    .txt-red{color:red;}
    .txt-green{color:chartreuse;}
    .txt-black{color:black;}

    .txt-e{margin-bottom: 2px;}
    .txt-d{margin-top: 5px;}
</style>
