//the component import itself so the functions are reusable in all the functions of the component
//currently reusing getQuestionTypeFrenchTraduction() in filterQuestion()
import shared from './shared';
import _ from 'lodash';

export default {
    components: { shared },
    questionComponent: (question) => {
        let retVal = "";
        switch (question.type) {
            //les choix suivants ne demandent pas d'autres éléments à part le texte de la question
            case "noanswer":
                retVal = "";
                break;
            case "text":
                retVal = "";
                break;
            case "single":
                retVal = "QuestionTypeChoices";
                break;
            case "multiple":
                retVal = "QuestionTypeChoices";
                break;
            case "date":
                retVal = "";
                break;
            case "time":
                retVal = "";
                break;
            case "number":
                retVal = "QuestionTypeNumber";
                break;
            case "scale":
                retVal = "QuestionTypeScale";
                break;
            case "compound":
                retVal = "QuestionTypeCompound";
                break;
            default:
                break;
        }
        return retVal;
    },
    optionsChange(options, question) {
        if (options.includes('images') && !question.optionImages) {
            question.optionImages = { imageCount: 1 };
        }
        if (options.includes('yesno') && !question.optionYesNo) {
            question.optionYesNo = {
                value: [
                    {
                        label: [{code: 'fr', text: 'Oui'}],
                        action: false
                    },
                    {
                        label: [{code: 'fr', text: 'Non'}],
                        action: true
                    }
                ],
                text: [{code: 'fr', text: ''}],
            };
        }
        if (options.includes('explanation') && !question.optionExplanation) {
            question.optionExplanation = { value: [{code: 'fr', text: ''}] };
        }
        if (options.includes('quantity') && !question.optionQuantity) {
            question.optionQuantity = { minValue: 0, maxValue: 0 };
        }
        if (options.includes('impact') && !question.optionImpactCode) {
            question.optionImpactCode = {  };
        }
        if (options.includes('yesnoimages') && !question.optionYesNoImages) {
            question.optionYesNoImages = { imageCount: 1 };
        }
    },

    optionYesNoAddOption(question) {
        question.optionYesNo.value.push({
            label: [{code: 'fr', text: ''}],
            action: false
        })
    },
    optionYesNoRemoveOption(question, index) {
        question.optionYesNo.value.splice(index, 1);
    },
    //gets state color in v-data-table
    getStateColor(state) {
        switch(state) {
            case 'New':
                return 'grey';

            case 'Pending':
                return 'warning';

            case 'Opened':
                return 'primary';

            case 'Closed':
                return 'success';

            case 'Processed':
                return 'pink';

            default:
                return 'transparent';
        }
    },
    //gets french label of states
    getStatesWithFrenchLabel(state) {
        switch(state) {
            case 'New':
                return 'Nouveau';

            case 'Opened':
                return 'En Cours';

            case 'Pending':
                return 'En Attente';

            case 'Closed':
                return 'Terminé';

            case 'Processed':
                return 'Traité';
        }
    },
    //language selection
    setLanguage(text, selectedLang) {
        //if language is the same as selected language and there is text
        if(!!text && Array.isArray(text)) {
            for(let lang of text) {
                if(lang.code === selectedLang && !!lang.text) {
                    return lang.text;
                }
            }

            //else we show the french text
            for(let lang of text) {
                if(lang.code === 'fr') {
                    return lang.text;
                }
            }
        }
    },
    //make french traduction of question type in dialog questions
    getQuestionTypeFrenchTraduction(type) {
        switch(type) {
            case 'noanswer':
                return 'Sans Réponse';

            case 'text':
                return 'Texte';

            case 'shorttext':
                return 'Texte Court';

            case 'single':
                return 'Choix Simple';

            case 'multiple':
                return 'Choix Multiple';

            case 'date':
                return 'Date';

            case 'time':
                return 'Heure';

            case 'number':
                return 'Nombre';

            case 'scale':
                return 'Échelle';

            case 'compound':
                return 'Composée';

            case 'contact':
                return 'Contact';

            case 'address':
                return 'Adresse';
        }
    },
    //gets categoris of a specific question
    getActiveCategories(question, categories) {
        return !!categories && categories.filter((category) => {
            return !!question.categoriesIds ? question.categoriesIds.includes(category.id) : false;
        });
    },
    //global filter function for all v-data-table index
    filterTableData(array, headers, tableName) {
        let filteredData = [...array];

        headers.forEach(header => {
            filteredData = filteredData.filter(item => {
                if (!!header.search && header.search.length > 0) {
                    const property = shared.getDataTraduction(item, header.value, tableName);

                    return property.toString().toLowerCase().includes(header.search.toLowerCase())
                }
                //filteredData.filter must always return true
                return true;
            });
        });

        return filteredData;
    },
    //private helper function for filterTableData
    getDataTraduction(item, headerValue, tableName) {
        //we use get method of lodash(_) library to dynamically get the object property even it is nested
        const data = _.get(item, headerValue);

        //TAGS INDEX
        if(tableName === 'tags' && !!data) {
            return data;
        }
        //SURVEYS INDEX
        else if(tableName === 'surveys' && !!data) {
            if(headerValue === 'title') {
                return data.map(lang => lang.text).join();
            }
            else {
                return data;
            }
        }
        //QUESTIONS INDEX
        else if(tableName === 'questions' && !!data) {
            switch(headerValue) {
                case 'type':
                    return shared.getQuestionTypeFrenchTraduction(data);

                case 'title':
                    return data.map(lang => lang.text).join();

                case 'categories':
                    return data.map(category => category.title).join();

                case 'tags':
                    return data.map(tag => tag.title).join();

                default:
                    return data;
            }
        }
        //ASSIGNATIONS INDEX
        else if(tableName === 'assignations' && !!data) {
            switch(headerValue) {
                case 'state':
                    return shared.getStatesWithFrenchLabel(data);

                case 'type':
                    return shared.getAssignationTypeTraduction(data);

                default:
                    return data;
            }
        }
        else if(!data) {
            return 'N/A';
        }
        return data;
    },
    //gets assignation type traduction
    getAssignationTypeTraduction(type) {
        switch(type) {
            case 'user':
                return 'Utilisateur';

            case 'contact':
                return 'Contact';

            case 'email':
                return 'Email';

            case 'autoAssigned':
                return 'Auto Assignation';
        }
    },
    //get full language text
    getFullLanguageText(lang) {
        switch(lang) {
            case 'fr':
                return 'Français';

            case 'en':
                return 'Anglais';

            case 'es':
                return 'Espagnol';
        }
    },
    //recursively resets all children id to null
    recursiveChildrenIdResets(children) {
        for(let child of children) {
            child.id = null;

            if(child.children.length > 0) {
                shared.recursiveChildrenIdResets(child.children);
            }
        }
    },

    //ANSWER
    //gets answer of question type single
    getSingleChoiceAnswer(question, answer, selectedLang) {
        if(!question.optionYesNo) {
            return !!question.details.choices[answer.value] ? (this.setLanguage(question.details.choices[answer.value], selectedLang) + (question.optionQuantity ? ` (Qté: ${answer.optionsValue.quantity})` : '') ) : 'N/A';
        }
        else {
            // this.setLanguage(question.optionYesNo.value[answer.optionsValue.optionYesNo].label, selectedLang); //C'est ce qui ajoutait le Non/Oui devant.
            return !!question.optionYesNo.value[answer.optionsValue.optionYesNo] ? '' : 'N/A';
        }
    },
    //gets all answers of question type multiple
    getMultipleChoiceAnswer(question, answer, selectedLang) {
        if(!!answer.value) {
            //the value is an array in this case, we use the reduce method
            let answerText = answer.value.reduce((accumulator, currentValue) => {
                return accumulator + ', ' + this.setLanguage(question.details.choices[currentValue], selectedLang) + (question.optionQuantity ? ` (Qté: ${answer.optionsValue.quantity[currentValue]})` : '');
            }, '');
            return answerText.substring(2);
        }

        return 'N/A'
    },


    //gets NC answer
    getNcAnswer(question, answer, selectedLang) {
        //if question has option yes, no details
        if(!!question.optionYesNo) {
            //if chosen answer is not the trigger action
            for(let option of question.optionYesNo.value) {
                for(let lang of option.label) {
                    //if the label language is the same than selected language of Gallup and the value is not the trigger action one
                    if(lang.code === selectedLang && lang.text === this.getSingleChoiceAnswer(question, answer, selectedLang) && !option.action) {
                        return `${this.getSingleChoiceAnswer(question, answer, selectedLang)}`;
                    }
                }
            }

            //if question is type multiple
            if(question.type === 'multiple') {
                return `${this.getSingleChoiceAnswer(question, answer, selectedLang)} ${this.getMultipleChoiceAnswer(question, answer, selectedLang)}`;
            }

            //else, for any other types
            return `${this.getSingleChoiceAnswer(question, answer, selectedLang)} ${answer.value}`;
        }
        //if question is type single
        else if(question.type === 'single') {
            return this.getSingleChoiceAnswer(question, answer, selectedLang);
        }
        //if question is type multiple
        else if(question.type === 'multiple') {
            return this.getMultipleChoiceAnswer(question, answer, selectedLang);
        }

        //if question is any other types
        return  answer.value;
    },
}
